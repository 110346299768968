/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import {
  Card,
  List,
  ListItem,
  Icon,
  Text,
  Bold,
  Flex,
  Title,
  Button,
  Grid,
} from "@tremor/react";
import React from "react";
import {
  BriefcaseIcon,
  DesktopComputerIcon,
  ShieldExclamationIcon,
  ShoppingBagIcon,
  ArrowNarrowRightIcon,
  LightningBoltIcon,
  HomeIcon,
  TruckIcon,
} from "@heroicons/react/solid";
import PagesIcon from '@mui/icons-material/Pages'; // Make sure to import PagesIcon
import nFormatter from "../../../utils/nFormatter";
import { Avatar } from "@mui/material";
import { getInitials } from "../../../utils/getInitials";

export default function InfluencerAnalytics({ influencer }) {
  const result = influencer?.social_media === "twitter" ? "Tweets" : "Posts";

  const totalTweets = influencer?.frequency_of_posts?.map(
    (tweet) => tweet?.no_of_posts
  );
  let reversed_array = [];

  totalTweets?.forEach((element) => {
    reversed_array.unshift(element);
  });

  const freqOfTweets = reversed_array?.slice(0, 30)?.reduce((freq, post) => {
    return freq + post;
  }, 0);

  const brands = Object.values(influencer?.brands || {});
  return (
    <Grid numItemsSm={2} numItemsLg={3} className="gap-6 pt-4 px-4 text-lg">
      <Card
        decoration="top"
        decorationColor={"purple"}
        style={{ height: "250px", overflowY: "scroll" }}
      >
        <Title>Top Mentions</Title>
        <p style={{ fontSize: "23px" }}>No data found at this time</p>

        {/* <Text>{item.name}</Text> */}
        <List className="mt-4">
          {Object.keys(influencer?.brands || {}).map((brand, i) => (
            <ListItem key={brand}>
              <Flex justifyContent="start" className="truncate space-x-4">
                <div className="truncate">
                  <Flex flexDirection="row" className="truncate">
                    <Avatar sx={{ width: "30px", height: "30px" }}>
                      {getInitials(brand)}
                    </Avatar>
                    <Bold style={{ marginLeft: "10px" }}>{brand}</Bold>
                  </Flex>
                </div>
              </Flex>
              <Text>{brands[i]}</Text>
            </ListItem>
          ))}
        </List>
      </Card>
      <Card decoration="top" decorationColor={"amber"}>
        <Title>{result} Analytics</Title>
        {/* <Text>{item.name}</Text> */}
        <List className="mt-4">
          <ListItem>
            <Flex justifyContent="start" className="truncate space-x-4">
              <div className="truncate">
                <Text className="truncate">
                  <Bold>{result} Frequency</Bold>
                </Text>
              </div>
            </Flex>
            <Text>
              {nFormatter(freqOfTweets) || 0}
              /month
            </Text>
          </ListItem>
          <ListItem>
            <Flex justifyContent="start" className="truncate space-x-4">
              <div className="truncate">
                <Text className="truncate">
                  <Bold>{result} per week</Bold>
                </Text>
              </div>
            </Flex>
            <Text>{Math.ceil(freqOfTweets / 4) || 0}</Text>
          </ListItem>
          <ListItem>
            <Flex justifyContent="start" className="truncate space-x-4">
              <div className="truncate">
                <Text className="truncate">
                  <Bold>No of Posts</Bold>
                </Text>
              </div>
            </Flex>
            <Text>
              {influencer?.total_posts - influencer?.account_analytics?.no_of_reels || 0}
            </Text>
          </ListItem>
        </List>
      </Card>
      <Card decoration="top" decorationColor={"pink"}>
        <Title>Account Analytics</Title>
        {/* <Text>{item.name}</Text> */}
        <List className="mt-4">
          <ListItem>
            <Flex justifyContent="start" className="truncate space-x-4">
              <div className="truncate">
                <Text className="truncate">
                  <Bold>
                    Avg. Engagement Rate
                    <span
                      style={{
                        fontWeight: "500",
                        color: "#1f1d9b",
                        fontSize: ".85rem",
                      }}
                    >
                      ( For All{" "}
                      {influencer?.social_media === "twitter"
                        ? "Tweets"
                        : "Posts"}{" "}
                      )
                    </span>
                  </Bold>
                </Text>
              </div>
            </Flex>
            <Text>{influencer?.avg_er_till_yesterday?.toFixed(3) || 0}%</Text>
          </ListItem>
          <ListItem>
            <Flex justifyContent="start" className="truncate space-x-4">
              <div className="truncate">
                <Text className="truncate">
                  <Bold>
                    {influencer?.social_media === "twitter"
                      ? "Avg. Retweets Received"
                      : "Video Engagement Rate"}
                  </Bold>
                </Text>
              </div>
            </Flex>
            <Text>
              {influencer?.social_media === "youtube"
                ? (influencer?.account_analytics?.avg_video_engagement_per_post?.toFixed(
                  2
                ) || 0) + "%"
                : influencer?.social_media === "instagram"
                  ? (influencer?.account_analytics?.avg_video_engagement_per_post?.toFixed(
                    2
                  ) || 0) + "%"
                  : nFormatter(
                    Math.ceil(influencer?.account_analytics?.avg_shares)
                  ) || 0}
            </Text>
          </ListItem>
          <ListItem>
            <Flex justifyContent="start" className="truncate space-x-4">
              <div className="truncate">
                <Text className="truncate">
                  <Bold>Avg. Likes Received</Bold>
                </Text>
              </div>
            </Flex>
            <Text>
              {nFormatter(
                Math.ceil(influencer?.["account_analytics"]?.avg_likes_received)
              ) || 0}
            </Text>
          </ListItem>
          <ListItem>
            <Flex justifyContent="start" className="truncate space-x-4">
              <div className="truncate">
                <Text className="truncate">
                  <Bold>Avg. Comments Received{" "}
                    {/* {influencer?.social_media === "twitter" ? "Tweet" : "Post"} */}
                  </Bold>
                </Text>
              </div>
            </Flex>
            <Text>{nFormatter(influencer?.most_viewed_post?.comments) || 0}</Text>
          </ListItem>
          <ListItem>
            <Flex justifyContent="start" className="truncate space-x-4">
              <div className="truncate">
                <Text className="truncate">
                  <Bold>Avg. Views{" "}
                    {/* {influencer?.social_media === "twitter" ? "Tweet" : "Post"} */}
                  </Bold>
                </Text>
              </div>
            </Flex>
            <Text>{nFormatter(influencer?.account_analytics?.avg_views) || 0}</Text>
          </ListItem>
        </List>
      </Card>
    </Grid>
  );
}
