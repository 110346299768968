/* eslint-disable */
import React from "react";
import { Button, Typography } from "@mui/material";
import { Container } from "@mui/system";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import noInfluencerFound from "../../images/noInfluencerFound.jpg";
const NoInfluencerFoundModal = React.lazy(() =>
  import("../../pages/error/NoInfluencerFound")
);

const NoInfluencerFound = ({ setOpen, open, searchInfluencer, socialMedia }) => {
  // Condition to check if there is a search influencer
  const shouldRenderMessage = searchInfluencer !== "";

  return (
    <Container
      style={{
        // Conditionally applying 
        width: shouldRenderMessage ? "90vw" : "15vw",
        display: shouldRenderMessage ? "flex" : "block", 
        flexDirection: shouldRenderMessage ? "row" : "unset", 
        alignItems: shouldRenderMessage ? "center" : "unset", 
        justifyContent: shouldRenderMessage ? "space-evenly" : "unset", 
      }}
    >
      {shouldRenderMessage && (
        <>
          <img
            loading="lazy"
            src={noInfluencerFound}
            alt="noInfluencerFound.jpg"
            style={{ width: "350px" }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography style={{ fontSize: ".95rem", textAlign: "center" }}>
              Sorry!! Currently there is no Influencer named{" "}
              <span style={{ cursor: "pointer", color: "blue" }}>
                @{searchInfluencer}
              </span>{" "}
              in our database, but we would like you to fill this form by
              clicking the button below!
            </Typography>
          </div>
        </>
      )}

      {/* Always show the Request button */}
      <Button
        variant="contained"
        startIcon={<PersonSearchIcon fontSize="small" />}
        style={{ width: "max-content", textAlign: "center" }}
        sx={{ mr: 1, mt: 1.5 }}
        onClick={() => {
          setOpen((prev) => !prev);
        }}
      >
        Click Here To Request
      </Button>
      {/* Lazy-loaded modal */}
      <NoInfluencerFoundModal
        setOpen={setOpen}
        open={open}
        searchInfluencer={searchInfluencer}
        socialMedia={socialMedia}
      />
    </Container>
  );
};

export default NoInfluencerFound;
