/* eslint-disable react/prop-types */
import React, { useState } from "react";
import "../../styles/payment/index.css";
import { Checkbox, TextField } from "@mui/material";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import RefundPolicyModal from "../terms/RefundPolicy";
import TermsAndConditionPolicyModal from "../terms/TermsAndCondition";

const Checkout = ({
  user,
  paymentGatewayHandler,
  plan_id,
  plan_amount,
  plan_title,
}) => {
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState({
    terms: false,
    refundPolicy: false,
  });

  const [refundModalOpen, setRefundModalOpen] = useState(false);
  const [termsAndConditionModalOpen, setTermsAndConditionModalOpen] =
    useState(false);

  const { handleSubmit, handleBlur, handleChange, errors, values, touched } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        fullName: user.user.fullName ? user.user.fullName : "",
        email: user.user.email ? user.user.email : "",
        phno: user.user.phno ? user.user.phno : "",
        gst: "",
        address_1: user.user.address_1 ? user.user.address_1 : "",
        address_2: user.user.address_2 ? user.user.address_2 : "",
        city: user.user.city ? user.user.city : "",
        zipcode: user.user.zipcode ? String(user.user.zipcode) : "",
        state: user.user.state ? user.user.state : "",
        country: user.user.country ? user.user.country : "",
        company: user.user.company ? user.user.company : "",
        company_email: user.user.company_email ? user.user.company_email : "",
      },
      validationSchema: Yup.object({
        fullName: Yup.string().required("Fullname is required"),
        email: Yup.string().email().required("Email is required"),
        phno: Yup.string()
          .length(10, "Enter a valid Phone Number")
          .required("Phone Number is required"),
        gst:
          user.user.country !== "IN"
            ? Yup.string().notRequired()
            : Yup.string()
              .length(15, "Enter a valid GST Number"),
              
        address_1: Yup.string().required("Address is required"),
        address_2: Yup.string(),
        city: Yup.string().required("city is required"),
        zipcode: Yup.string("^d{6}").required("zipcode is required"),
        state: Yup.string().required("State is required"),
        country: Yup.string().required("Country is required"),
        company: Yup.string().required("Company Name is required"),
        company_email: Yup.string()
          .email()
          .required("Company Email is required"),
      }),
      onSubmit: async (values) => {
        if (!(checked.terms && checked.refundPolicy)) {
          return toast.warn("Agree to Terms & Refund Policy");
        }
        setLoading((prev) => !prev);
        const data = {
          user_id: user.user.id,
          fullName: values.fullName,
          state: values.state,
          country: values.country,
          city: values.city,
          zipcode: +values.zipcode,
          company: values.company,
          phno: values.phno,
          company_email: values.company_email,
          address_1: values.address_1,
          address_2: values.address_2.length > 0 ? values.address_2 : null,
          is_confirmed: true,
          plan_id: plan_id,
          plan_title: plan_title,
          recharge_amt: plan_amount,
          gst_no: user.user.country === "AE" ? null : values.gst,
        };
        paymentGatewayHandler(data);
      },
    });

  return (
    <div className="payment_container">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          // paymentGatewayHandler();
        }}
      >
        <p className="mb-6 mt-3 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 text-center w-full">
          BILLING FORM
        </p>
        <div className="grid grid-cols-2 gap-4 w-full">
          <TextField
            label="Name"
            variant="outlined"
            name="fullName"
            id="fullName"
            value={values.fullName}
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(touched.fullName && errors.fullName)}
            fullWidth
            helperText={touched.fullName && errors.fullName}
          />
          <TextField
            id="email"
            name="email"
            label="Email"
            type="email"
            variant="outlined"
            value={values.email}
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
          />
          <TextField
            label="Phone"
            name="phno"
            id="phno"
            variant="outlined"
            value={values.phno}
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(touched.phno && errors.phno)}
            fullWidth
            helperText={touched.phno && errors.phno}
          />
          {user.user.country !== "IN" ? null : (
            <TextField
              id="gst"
              name="gst"
              label="GST (Optional)"
              variant="outlined"
              value={values.gst}
              onBlur={handleBlur}
              onChange={handleChange}
              error={Boolean(touched.gst && errors.gst)}
              fullWidth
              helperText={touched.gst && errors.gst}
            />
          )}
          <TextField
            id="company"
            name="company"
            label="Company"
            variant="outlined"
            value={values.company}
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(touched.company && errors.company)}
            fullWidth
            helperText={touched.company && errors.company}
          />
          <TextField
            id="company_email"
            name="company_email"
            label="Company Email"
            variant="outlined"
            value={values.company_email}
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(touched.company_email && errors.company_email)}
            fullWidth
            helperText={touched.company_email && errors.company_email}
          />
          <TextField
            id="address_1"
            name="address_1"
            label="Address 1"
            variant="outlined"
            required
            // value={address ? address.display_name : ""}
            value={values.address_1}
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(touched.address_1 && errors.address_1)}
            fullWidth
            helperText={touched.address_1 && errors.address_1}
          />
          <TextField
            id="address_2"
            name="address_2"
            label="Address 2 (Optional)"
            variant="outlined"
            value={values.address_2}
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(touched.address_2 && errors.address_2)}
            fullWidth
            helperText={touched.address_2 && errors.address_2}
          />
          <TextField
            id="city"
            name="city"
            label="city"
            variant="outlined"
            required
            value={values.city}
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(touched.city && errors.city)}
            fullWidth
            helperText={touched.city && errors.city}
          />
          <TextField
            id="state"
            name="state"
            label="State"
            variant="outlined"
            // value={address ? address.address.state : ""}
            value={values.state}
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(touched.state && errors.state)}
            fullWidth
            helperText={touched.state && errors.state}
          />
          <TextField
            id="zipcode"
            name="zipcode"
            label="ZIP Code"
            variant="outlined"
            value={values.zipcode}
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(touched.zipcode && errors.zipcode)}
            fullWidth
            helperText={touched.zipcode && errors.zipcode}
          />
          <TextField
            id="country"
            name="country"
            label="Country"
            variant="outlined"
            // value={address ? address.address.country : ""}
            value={values.country}
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(touched.country && errors.country)}
            fullWidth
            helperText={touched.country && errors.country}
          />
        </div>
        <div className="flex flex-row justify-center items-center w-full">
          <div className="w-full flex flex-row items-center justify-center">
            <Checkbox
              name="remember"
              id="remember"
              style={{ borderRadius: "8px" }}
              value={checked.terms}
              onChange={(e) =>
                setChecked({
                  ...checked,
                  terms: e.target.checked,
                })
              }
            />
            <label className="cursor-pointer" htmlFor="remember">
              Agree{" "}
              <span
                className=" text-blue-700 font-semibold"
                onClick={() => setTermsAndConditionModalOpen(true)}
              >
                Terms & Condition
              </span>{" "}
            </label>
          </div>
          <div className="w-full flex flex-row items-center justify-center">
            <Checkbox
              name="refund-policy"
              id="refund-policy"
              style={{ borderRadius: "8px" }}
              value={checked.refundPolicy}
              onChange={(e) =>
                setChecked({
                  ...checked,
                  refundPolicy: e.target.checked,
                })
              }
            />
            <label className="cursor-pointer" htmlFor="refund-policy">
              Agree{" "}
              <span
                className=" text-blue-700 font-semibold"
                onClick={() => setRefundModalOpen(true)}
              >
                Refund Policy
              </span>{" "}
            </label>
          </div>
        </div>
        {!loading ? (
          <button onClick={handleSubmit} type="submit" className="submit-btn">
            Proceed to Checkout
          </button>
        ) : (
          <button
            disabled
            onClick={handleSubmit}
            type="submit"
            className="submit-btn"
          >
            Processing
          </button>
        )}
      </form>
      {refundModalOpen && (
        <RefundPolicyModal
          open={refundModalOpen}
          setOpen={setRefundModalOpen}
        />
      )}
      {termsAndConditionModalOpen && (
        <TermsAndConditionPolicyModal
          open={termsAndConditionModalOpen}
          setOpen={setTermsAndConditionModalOpen}
        />
      )}
    </div>
  );
};

export default Checkout;
