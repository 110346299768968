/* eslint-disable react/prop-types */
import React from "react";

import { CardHeader, TextField } from "@mui/material";
import VerifyLinkTextField from "../shared/VerifyLinkTextField";

const flex = {
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "center",
  flexDirection: "column",
};

const CompanyDetails = ({
  valid,
  setValid,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}) => {
  return (
    <div style={{ ...flex, width: "100%" }}>
      <CardHeader
        title="Company Details"
        sx={{ m: 0, p: 0, ml: 3, mt: .5 }}
      />
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(270px, 1fr))",
          gridGap: ".85rem",
          width: "100%",
          padding: "1rem",
          paddingTop: "0%"
        }}
      >
        <TextField
          autoComplete="off"
          name="companyName"
          id="companyName"
          label="Company Name"
          placeholder="Enter Your Company Name"
          value={values.companyName}
          onChange={handleChange}
          onBlur={handleBlur}
          fullWidth
          helperText={touched.companyName && errors.companyName}
          error={errors.companyName && touched.companyName}
          style={{ marginTop: "1rem" }}
          
        />
        <TextField
          autoComplete="off"
          name="companyEmail"
          id="companyEmail"
          label="Company Email Id"
          placeholder="Enter Your Company Email Id"
          value={values.companyEmail}
          onChange={handleChange}
          onBlur={handleBlur}
          fullWidth
          helperText={touched.companyEmail && errors.companyEmail}
          error={errors.companyEmail && touched.companyEmail}
          style={{ marginTop: "1rem" }}
          
        />
        <VerifyLinkTextField
          autoComplete="off"
          name="companyLink"
          id="companyLink"
          label="Company Link"
          placeholder="Enter Your Company Link"
          value={values.companyLink}
          onChange={handleChange}
          onBlur={handleBlur}
          touched={touched.companyLink}
          fullWidth
          helperText={touched.companyLink && errors.companyLink}
          error={errors.companyLink && touched.companyLink}
          logic={"VerifyLink"}
          style={{ marginTop: "1rem" }}
          valid={valid}
          setValid={setValid}
          
        />
      </div>
    </div>
  );
};

export default CompanyDetails;
