import { MenuItem, MenuList, Popover } from '@mui/material';
/*eslint-disable*/
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { userLogout } from '../../context/slice/userSlice';

export const AccountPopover = (props) => {
  const { anchorEl, onClose, open, setTab = () => { } } = props;  // Provide a default no-op function for setTab
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function viewMyProfileHandler() {
    navigate("/user-profile?tab=0");
    onClose?.();
  }

  function viewMyNotificationHandler() {
    navigate("/user-notification")
    onClose?.();
  }

  function viewMyInfluencersHandler() {
    navigate("/user-influencers")
    onClose?.();
  }

  function viewMySubscriptionHandler() {
    navigate("/user-profile?tab=3")
    onClose?.();
  }

  function viewMyAccessedReportsHandler() {
    navigate("/user-access-resports")
    onClose?.();
  }

  function viewMyTransactionsHandler() {
    navigate("/user-transactions")
    onClose?.();
  }

  const handleSignOut = async () => {
    onClose?.();
    await dispatch(userLogout());
    navigate("/login");
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: { width: '200px' },
      }}
      style={{ position: "absolute", top: "8%", left: "-2%" }}
    >
      <MenuList
        disablePadding
        sx={{
          '& > *': {
            '&:first-of-type': {
              borderTopColor: 'divider',
              borderTopStyle: 'solid',
              borderTopWidth: '1px',
            },
            padding: '12px 16px',
          },
        }}
      >
        <MenuItem onClick={viewMyProfileHandler}>
          My Profile
        </MenuItem>
        <MenuItem onClick={viewMyNotificationHandler}>
          Notifications
        </MenuItem>
        <MenuItem onClick={viewMyInfluencersHandler}>
          Favourite Influencers
        </MenuItem>
        <MenuItem onClick={viewMySubscriptionHandler}>
          Subscription
        </MenuItem>
        <MenuItem onClick={viewMyAccessedReportsHandler}>
          Accessed Reports
        </MenuItem>
        <MenuItem onClick={viewMyTransactionsHandler}>
          Transactions
        </MenuItem>
      </MenuList>
      <MenuList disablePadding>
        <MenuItem onClick={handleSignOut}>
          Sign out
        </MenuItem>
      </MenuList>
    </Popover>
  );
};
