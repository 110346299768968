import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  getCampaignDetail,
  getReportCampaign,
} from "../../context/slice/CampaignSlice";
import { useDispatch, useSelector } from "react-redux";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ChatIcon from "@mui/icons-material/Chat";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TwitterIcon from "@mui/icons-material/Twitter";
import ReplyIcon from "@mui/icons-material/Reply";
import ExploreIcon from "@mui/icons-material/Explore";
import LyricsIcon from "@mui/icons-material/Lyrics";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { CampaignWidget } from "../../components/campaigns/CampaignDetailPage/CampaignWidget";
import nFormatter from "../../utils/nFormatter";
import {
  Avatar,
  Backdrop,
  Box,
  Breadcrumbs,
  Button,
  CardHeader,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import PostsContainer from "../../components/campaigns/PostsContainer";
import InfluencersPosts from "../../components/campaigns/CampaignDetailPage/InfluencersPosts";
import timeAgo from "../../utils/DateConverter";
import NoOverview from "../../images/NoData/NoOverview.avif";
import ErrorImg from "../../images/error/ErrorPage.avif";
// import { hashtagReport as campaign } from "../../__mocks__/hashtagReport";
import Youtube from "../../images/social_media/youtubeLogo.png";
import Twitter from "../../images/social_media/twitterLogo.png";
import Instagram from "../../images/social_media/instagramLogo.jfif";
import { toast } from "react-toastify";
import { toastOptions } from "../../utils/toastOptions";
import HastagsWordCloud from "../../components/influencers/stats/HastagsWordCloud";

const HashtagDetail = () => {
  const { hashtagId } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  // , campaignNewReport: campaign
  const { singleCampaign, campaignNewReport: campaign } = useSelector(
    (s) => s.campaign
  );

  async function CampaignDetailsHandler() {
    setLoading((prev) => !prev);
    setError(false);
    try {
      await dispatch(getReportCampaign({ id: hashtagId })).unwrap();
      await dispatch(getCampaignDetail({ campaign_id: hashtagId })).unwrap();
      setLoading((prev) => !prev);
    } catch (error) {
      setError((prev) => !prev);
      setLoading((prev) => !prev);
      toast.error(
        error.detail || error.msg || "Server error",
        toastOptions
      );
    }
  }

  useEffect(() => {
    CampaignDetailsHandler();
  }, [hashtagId]);

  return (
    <>
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="primary" />
        </Backdrop>
      )}
      {!loading && error && (
        <Box
          sx={{
            // width: "85vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <img
            loading="lazy"
            src={ErrorImg}
            alt="ErrorImg"
            style={{ width: "400px" }}
          />
          <Button
            sx={{ fontWeight: "500", fontSize: "1rem" }}
            onClick={CampaignDetailsHandler}
            variant="contained"
            color="error"
            size="small"
          >
            Try Again
          </Button>
        </Box>
      )}
      {!loading && !error && singleCampaign && (
        <Container>
          <Container maxWidth={false}>
            <div className="Breadcrumbscard" style={{ marginTop: "30px", marginLeft: "-129px" }}>
              <div style={{ display: "flex" }}>
                <Breadcrumbs
                  style={{ margin: "0 .2rem", marginBottom: "1.5rem" }}
                  aria-label="breadcrumb"
                >
                  <Link
                    style={{ cursor: "pointer" }}
                    underline="hover"
                    color="inherit"
                    to={"/"}
                  >
                    Home
                  </Link>
                  <Typography color="text.primary">HashTags</Typography>
                </Breadcrumbs>
              </div>
            </div>
          </Container>
          <h1
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
              marginBottom: "1rem",
            }}
          >
            {singleCampaign?.campaign_identify_name}
            <Avatar
              sx={{ ml: 2 }}
              src={
                singleCampaign?.campaign_social_media === "twitter"
                  ? Twitter
                  : singleCampaign?.campaign_social_media === "youtube"
                    ? Youtube
                    : Instagram
              }
            />
          </h1>
          <section>
            <div className="CompetitorAnalysis">
              <div
                style={{ width: "100%", padding: "2rem 0" }}
                className="right"
              >
                <h2
                  style={{
                    textAlign: "start",
                    width: "100%",
                    color: "rgb(85, 83, 83",
                    padding: "0% 1rem",
                  }}
                >
                  Overall Performance
                </h2>
                <div style={{ width: "100%", marginTop: "1rem" }}>
                  {Object.keys(campaign ? campaign : {})?.length > 0 ? (
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns:
                          "repeat(auto-fit, minmax(400px, 1fr))",
                        gridGap: ".75rem",
                      }}
                    >
                      <CampaignWidget
                        title={"Post Performance"}
                        value={
                          "Total " +
                          (campaign?.total_posts || 0) +
                          " posts Analyzed"
                        }
                        icon={<ContentPasteIcon />}
                        color={"error.main"}
                      />
                      <CampaignWidget
                        title={"Likes Performance"}
                        value={
                          "Total " +
                          nFormatter(campaign?.likes || 0) +
                          " likes received on all posts"
                        }
                        icon={<ThumbUpOffAltIcon />}
                        color={"info.dark"}
                      />
                      <CampaignWidget
                        title={"Comment Analysis"}
                        value={
                          "Total " +
                          nFormatter(campaign?.comments || 0) +
                          " comments received on all posts"
                        }
                        icon={<ChatIcon />}
                        color={"warning.main"}
                      />
                      <CampaignWidget
                        title={"Views Analysis"}
                        value={
                          "Total " +
                          nFormatter(campaign?.views || 0) +
                          " views received on all posts"
                        }
                        icon={<VisibilityIcon />}
                        color={"secondary.light"}
                      />
                      {campaign?.social_media === "twitter" && (
                        <>
                          <CampaignWidget
                            title={"Retweets Analysis"}
                            value={
                              "Total " +
                              nFormatter(campaign?.shares | 0) +
                              " retweets received on all posts"
                            }
                            icon={<TwitterIcon />}
                            color={"info.light"}
                          />
                          <CampaignWidget
                            title={"Replies Analysis"}
                            value={
                              "Total " +
                              nFormatter(campaign?.replies | 0) +
                              " replies received on all posts"
                            }
                            icon={<ReplyIcon />}
                            color={"error.light"}
                          />
                          <CampaignWidget
                            title={"Qoutes Analysis"}
                            value={
                              "Total " +
                              nFormatter(campaign?.quotes | 0) +
                              " quotes received on all posts"
                            }
                            icon={<LyricsIcon />}
                            color={"primary.dark"}
                          />
                          <CampaignWidget
                            title={"Impressions Analysis"}
                            value={
                              "Total " +
                              nFormatter(campaign?.impressions | 0) +
                              " impressions received on all posts"
                            }
                            icon={<ExploreIcon />}
                            color={"text.primary"}
                          />
                          <CampaignWidget
                            title={"Bookmarks Analysis"}
                            value={
                              "Total " +
                              nFormatter(campaign?.bookmarks | 0) +
                              " bookmarks received on all posts"
                            }
                            icon={<BookmarkIcon />}
                            color={"secondary.dark"}
                          />
                        </>
                      )}
                    </div>
                  ) : (
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        paddingBottom: "3.75rem",
                      }}
                    >
                      <img
                        loading="lazy"
                        src={NoOverview}
                        alt="NoOverview"
                        style={{ width: "400px" }}
                      />
                      <Typography
                        style={{ fontSize: "1.02rem", fontWeight: "600" }}
                      >
                        No Overview Avaliable
                      </Typography>
                    </Box>
                  )}
                </div>
              </div>
            </div>
            <div>
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
                container
                spacing={3}
              >
                {(campaign?.most_liked_post?.post?.trim()?.length > 0 ||
                  campaign?.most_liked_post?.tweet?.trim()?.length > 0) && (
                    <Grid item lg={6} md={12} xl={9} xs={12}>
                      <>
                        <CardHeader
                          title={
                            campaign?.social_media === "twitter"
                              ? "Most Liked Tweet"
                              : "Most Liked Post"
                          }
                        />
                        <InfluencersPosts
                          user={campaign?.most_liked_post?.handle_name || "Anonymous"}
                          createdAt={timeAgo(campaign?.most_liked_post?.created_at)}
                          likes={campaign?.most_liked_post?.likes}
                          comments={campaign?.most_liked_post?.comments}
                          views={campaign?.most_liked_post?.views}
                          title={campaign?.most_liked_post?.post || campaign?.most_liked_post?.tweet || ""}
                          shares={campaign?.most_liked_post?.retweet_count}
                          link={campaign?.most_liked_post?.link}
                          style={{ minHeight: "213px" }}
                        />
                      </>
                    </Grid>
                  )}
                {(campaign?.most_commented_post?.post?.trim()?.length > 0 ||
                  campaign?.most_commented_post?.tweet?.trim()?.length > 0) && (
                    <Grid item lg={6} md={12} xl={9} xs={12}>
                      <>
                        <CardHeader
                          title={
                            campaign?.social_media === "twitter"
                              ? "Most Commented Tweet"
                              : "Most Commented Post"
                          }
                        />
                        <InfluencersPosts
                          user={
                            campaign?.most_commented_post?.handle_name ||
                            "Anonymous"
                          }
                          createdAt={timeAgo(
                            campaign?.most_commented_post?.created_at
                          )}
                          likes={campaign?.most_commented_post?.likes}
                          comments={campaign?.most_commented_post?.comments}
                          views={campaign?.most_commented_post?.views}
                          title={
                            campaign?.most_commented_post?.post ||
                            campaign?.most_commented_post?.tweet ||
                            ""
                          }
                          shares={campaign?.most_commented_post?.retweet_count}
                          link={campaign?.most_commented_post?.link}
                          style={{ minHeight: "150px" }}
                        />
                      </>
                    </Grid>
                  )}
                {(campaign?.most_viewed_post?.post?.trim()?.length > 0 ||
                  campaign?.most_viewed_post?.tweet?.trim()?.length > 0) && (
                    <Grid item lg={6} md={12} xl={9} xs={12}>
                      <>
                        <CardHeader
                          title={
                            campaign?.social_media === "twitter"
                              ? "Most Viewed Tweet"
                              : "Most Viewed Post"
                          }
                        />
                        <InfluencersPosts
                          user={
                            campaign?.most_viewed_post?.handle_name || "Anonymous"
                          }
                          createdAt={timeAgo(
                            campaign?.most_viewed_post?.created_at
                          )}
                          likes={campaign?.most_viewed_post?.likes}
                          comments={campaign?.most_viewed_post?.comments}
                          views={campaign?.most_viewed_post?.views}
                          title={
                            campaign?.most_viewed_post?.post ||
                            campaign?.most_viewed_post?.tweet ||
                            ""
                          }
                          shares={campaign?.most_viewed_post?.retweet_count}
                          link={campaign?.most_viewed_post?.link}
                          style={{ minHeight: "150px" }}
                        />
                      </>
                    </Grid>
                  )}
                {(campaign?.most_shared_post?.post?.trim()?.length > 0 ||
                  campaign?.most_shared_post?.tweet?.trim()?.length > 0) && (
                    <Grid item lg={6} md={12} xl={9} xs={12}>
                      <>
                        <CardHeader
                          title={
                            campaign?.social_media === "twitter"
                              ? "Most Retweeted Tweet"
                              : "Most Shared Post"
                          }
                        />
                        <InfluencersPosts
                          user={
                            campaign?.most_shared_post?.handle_name || "Anonymous"
                          }
                          createdAt={timeAgo(
                            campaign?.most_shared_post?.created_at
                          )}
                          likes={campaign?.most_shared_post?.likes}
                          comments={campaign?.most_shared_post?.comments}
                          views={campaign?.most_shared_post?.views}
                          title={
                            campaign?.most_shared_post?.post ||
                            campaign?.most_shared_post?.tweet ||
                            ""
                          }
                          shares={campaign?.most_shared_post?.retweet_count}
                          link={campaign?.most_shared_post?.link}
                          style={{ minHeight: "150px" }}
                        />
                      </>
                    </Grid>
                  )}
                {(campaign?.most_impressions_post?.post?.trim()?.length > 0 ||
                  campaign?.most_impressions_post?.tweet?.trim()?.length > 0) && (
                    <Grid item lg={6} md={12} xl={9} xs={12}>
                      <>
                        <CardHeader
                          title={
                            campaign?.social_media === "twitter"
                              ? "Most Impressions Tweet"
                              : "Most Impressions Post"
                          }
                        />
                        <InfluencersPosts
                          user={
                            campaign?.most_impressions_post?.handle_name ||
                            "Anonymous"
                          }
                          createdAt={timeAgo(
                            campaign?.most_impressions_post?.created_at
                          )}
                          likes={campaign?.most_impressions_post?.likes}
                          comments={campaign?.most_impressions_post?.comments}
                          views={campaign?.most_impressions_post?.views}
                          title={
                            campaign?.most_impressions_post?.post ||
                            campaign?.most_impressions_post?.tweet ||
                            ""
                          }
                          shares={campaign?.most_impressions_post?.retweet_count}
                          link={campaign?.most_impressions_post?.link}
                          style={{ minHeight: "150px" }}
                        />
                      </>
                    </Grid>
                  )}
                {(campaign?.most_replied_post?.post?.trim()?.length > 0 ||
                  campaign?.most_replied_post?.tweet?.trim()?.length > 0) && (
                    <Grid item lg={6} md={12} xl={9} xs={12}>
                      <>
                        <CardHeader
                          title={
                            campaign?.social_media === "twitter"
                              ? "Most Replied Tweet"
                              : "Most Replied Post"
                          }
                        />
                        <InfluencersPosts
                          user={
                            campaign?.most_replied_post?.handle_name || "Anonymous"
                          }
                          createdAt={timeAgo(
                            campaign?.most_replied_post?.created_at
                          )}
                          likes={campaign?.most_replied_post?.likes}
                          comments={campaign?.most_replied_post?.comments}
                          views={campaign?.most_replied_post?.views}
                          title={
                            campaign?.most_replied_post?.post ||
                            campaign?.most_replied_post?.tweet ||
                            ""
                          }
                          shares={campaign?.most_replied_post?.retweet_count}
                          link={campaign?.most_replied_post?.link}
                          style={{ minHeight: "150px" }}
                        />
                      </>
                    </Grid>
                  )}
                {(campaign?.most_bookmark_post?.post?.trim()?.length > 0 ||
                  campaign?.most_bookmark_post?.tweet?.trim()?.length > 0) && (
                    <Grid item lg={6} md={12} xl={9} xs={12}>
                      <>
                        <CardHeader
                          title={
                            campaign?.social_media === "twitter"
                              ? "Most Bookmarked Tweet"
                              : "Most Bookmarked Post"
                          }
                        />
                        <InfluencersPosts
                          user={
                            campaign?.most_bookmark_post?.handle_name || "Anonymous"
                          }
                          createdAt={timeAgo(
                            campaign?.most_bookmark_post?.created_at
                          )}
                          likes={campaign?.most_bookmark_post?.likes}
                          comments={campaign?.most_bookmark_post?.comments}
                          views={campaign?.most_bookmark_post?.views}
                          title={
                            campaign?.most_bookmark_post?.post ||
                            campaign?.most_bookmark_post?.tweet ||
                            ""
                          }
                          shares={campaign?.most_bookmark_post?.retweet_count}
                          link={campaign?.most_bookmark_post?.link}
                          style={{ minHeight: "150px" }}
                        />
                      </>
                    </Grid>
                  )}
              </Grid>
            </div>
            {campaign?.social_media === "instagram" && (
              <section>
                <PostsContainer
                  title={"Paid Patnership Posts"}
                  campaign={campaign?.paid_partnership}
                  socialMedia={singleCampaign?.campaign_social_media}
                />
                <PostsContainer
                  title={"Organic Posts"}
                  campaign={campaign?.organic_posts}
                  socialMedia={singleCampaign?.campaign_social_media}
                />
              </section>
            )}
            {campaign?.social_media !== "instagram" && (
              <Grid item lg={6} md={12} xl={9} xs={12}>
                <HastagsWordCloud
                  hashtags={campaign?.hashtags || []}
                  handlename={singleCampaign?.campaign_identify_name}
                />
              </Grid>
            )}

          </section>
        </Container>
      )}
    </>
  );
};

export default HashtagDetail;
