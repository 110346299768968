
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './Dropdown.css';

const Dropdown = ({ items, onSelect }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [haveText, setHaveText] = useState("");

    const handleClick = () => {
        setIsOpen(!isOpen);
    }

    const handleText = (ev) => {
        const selectedItem = ev.currentTarget.textContent;
        setHaveText(selectedItem);
        onSelect(selectedItem); // Call the onSelect prop for navigation
        setIsOpen(false); // Close the dropdown after selection
    }

    const itemList = (items) => {
        return items.map((item) => (
            <div
                onClick={handleText}
                className="dropdown__item1"
                key={item}>
                {item}
            </div>
        ));
    }

    return (
        <div
            className={isOpen ? "dropdown1 active" : "dropdown1"}
            onClick={handleClick}>
            <div className="dropdown__text1">
                {!haveText ? "Profile Section" : haveText}
            </div>
            <div className="dropdown__items1">
                {itemList(items)}
            </div>
        </div>
    );
}

// Define prop types for the Dropdown component
Dropdown.propTypes = {
    items: PropTypes.arrayOf(PropTypes.string).isRequired,
    onSelect: PropTypes.func.isRequired, // Validate that onSelect is a function
};

export default Dropdown;
