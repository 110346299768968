/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import {
  Card,
  Title,
  TabList,
  Tab,
  TabGroup,
  TabPanel,
  TabPanels,
  AreaChart,
} from "@tremor/react";
import { Category } from "@mui/icons-material";

import React, { useState } from "react";

export default function LikesPerformanceChart({ data, posts }) {
  const [selectedIndex, setSelectedIndex] = useState("1M");

  return (
    <>
      <Card style={{
        padding: '20px',
        borderRadius: '0px',
        width: '100%',
        margin: '0 auto'
      }}>
        <Title style={{
          padding: "12px",
          fontSize: '1.5rem',
          marginBottom: '1rem',
          color: '#333'
        }}>
          Post Category
        </Title>
        <hr style={{ width: "100%" }} />
        <ul style={{
          listStyleType: 'none',
          padding: 0
        }}>
          {posts && Object.keys(posts).map((category, index) => (
            <li key={index} style={{
              fontSize: '1.2rem',
              marginBottom: '0.5rem',
              color: '#666',
              borderRadius: '5px',
              backgroundColor: '#fff',
              padding: '10px',
              // boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              textAlign: 'center'
            }}>
              {category}
            </li>
          ))}
        </ul>
      </Card>
    </>
  );
}
