import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { DashboardNavbar } from "../components/shared/DashbardNavbar";
import { DashboardSidebar } from "../components/shared/DashboardSidebar";
import useMediaQuery from "@mui/material/useMediaQuery";
import PageLoader from "../pages/loader/PageLoader";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { toastOptions } from "../utils/toastOptions";

const DashboardLayoutRoot = styled("div")(() => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  paddingTop: 64,
}));

export const DashboardLayout = (props) => {
  // eslint-disable-next-line react/prop-types
  const { children } = props;
  const { user } = useSelector((store) => store.users);
  const navigate = useNavigate();
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const MediaQuery = useMediaQuery("(min-width:950px)");
  const [client, setClient] = useState(false);

  useEffect(() => {
    if (
      user?.token?.access &&
      user?.user?.country &&
      !["IN", "AE", "MY", "VN", "SG", "TH", "ID"].includes(user.user.country) &&
      window.location.pathname !== "/user-profile"
    ) {
      toast.warn("Update your country to move forward!!", toastOptions);
      navigate("/user-profile");
    }
  }, [user, user?.token?.access, user?.user?.country, window.location.pathname]);

  useEffect(() => {
    setClient(true);
  }, []);

  return (
    <>
      {!client ? (
        <PageLoader />
      ) : (
        <>
          <DashboardLayoutRoot>
            <Box
              sx={{
                display: "flex",
                flex: "1 1 auto",
                flexDirection: "column",
                width: "100%",
                paddingLeft: MediaQuery ? 9 : 0,
              }}
            >
              {children}
            </Box>
          </DashboardLayoutRoot>
          <DashboardNavbar
            open={isSidebarOpen}
            setSidebarOpen={setSidebarOpen}
          />
          <DashboardSidebar
            onClose={() => setSidebarOpen(false)}
            onOpen={() => setSidebarOpen(true)}
            open={isSidebarOpen}
          />
        </>
      )}
    </>
  );
};
