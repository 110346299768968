import * as React from "react";
import Box from "@mui/material/Box";
/* eslint-disable */
export default function SettingsTab({ value, setValue }) {
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box
        sx={{
          bgcolor: "background.paper",
          mt: 1,
          width: "100%",
        }}
      >
      </Box>
    </>
  );
}
