/* eslint-disable */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BaseURL } from "../../services/API_services";
import { toast } from "react-toastify";
import { toastOptions } from "../../utils/toastOptions";
import { reelsData } from "../../__mocks__/reels";
// import { twitterHashtagReport } from "../../__mocks__/hashtagReport";

export const getAllCampaigns = createAsyncThunk(
  "campaign/getAllCampaigns",
  async (payload, { rejectWithValue, getState }) => {
    try {
      const res = await axios.get(`${BaseURL}campaign/groups/`, payload);
      if (res?.response?.status >= 400) {
        return rejectWithValue(res.response.data);
      }
      return res.data;
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCampaignDetail = createAsyncThunk(
  "campaign/getCampaignDetail",
  async (payload, { rejectWithValue, getState }) => {
    try {
      const res = await axios.get(
        `${BaseURL}campaign/groups/${payload.campaign_id}`
      );
      if (res?.response?.status >= 400) {
        return rejectWithValue(res.response.data);
      }
      return res.data;
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDemoReport = createAsyncThunk(
  "campaign/getDemoReport",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await axios.get(
        `${BaseURL}common/base_report/?report_type=campaigns`
      );
      if (res?.response?.status >= 400) {
        return rejectWithValue(res.response.data);
      }
      return fulfillWithValue(res.data);
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);



export const getInfluencersFromSingleCampaign = createAsyncThunk(
  "campaign/getInfluencersFromSingleCampaign",
  async (payload, { rejectWithValue, getState }) => {
    try {
      const res = await axios.get(
        `${BaseURL}campaign/influencers/?campaign_id=${payload.campaign_id}`,
        payload
      );
      if (res?.response?.status >= 400) {
        return rejectWithValue(res.response.data);
      }
      return res.data;
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getNoOfInfluencersFromCampaign = createAsyncThunk(
  "campaign/getNoOfInfluencersFromCampaign",
  async (_payload, { rejectWithValue }) => {
    try {
      const res = await axios.get(`${BaseURL}campaign/usergroupid/`);
      if (res?.response?.status >= 400) {
        return rejectWithValue(res.response.data);
      }
      return res.data;
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getReportCampaign = createAsyncThunk(
  "campaign/getReportCampaign",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await axios.get(`${BaseURL}campaign/reports/${payload.id}`);
      if (res?.response?.status >= 400) {
        return rejectWithValue(res.response.data);
      }
      return fulfillWithValue(res.data);
      // return fulfillWithValue(twitterHashtagReport);
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const requestHashtag = createAsyncThunk(
  "campaign/requestHashtag",
  async (payload, { rejectWithValue }) => {
    try {
      const res = await axios.post(
        `${BaseURL}influencer/newinfluencer/`,
        payload
      );
      if (res?.response?.status >= 400) {
        return rejectWithValue(res.response.data);
      }
      return res.data;
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getHashtagHistory = createAsyncThunk(
  "campaign/getHashtagHistory",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await axios.get(
        `${BaseURL}influencer/userhashtags/`,
        payload
      );
      if (res?.response?.status >= 400) {
        return rejectWithValue(res.response.data);
      }
      return fulfillWithValue(res.data);
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const addReel = createAsyncThunk(
  "campaign/addReel",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await axios.post(`${BaseURL}campaign/addreels/`, payload);
      if (res?.response?.status >= 400) {
        return rejectWithValue(res.response.data);
      }
      return fulfillWithValue(res.data);
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);


export const addReelWithFile = createAsyncThunk(
  'campaign/addReelWithFile',
  async (formData, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${BaseURL}campaign/addreels/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return res.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error uploading reels');
    }
  }
);


export const getReelsReport = createAsyncThunk(
  "campaign/getReelsReport",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await axios.get(`${BaseURL}campaign/reels_reports/${payload.campaign_id}`, payload);
      if (res?.response?.status >= 400) {
        return rejectWithValue(res.response.data);
      }
      return fulfillWithValue(res.data);
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Campaign Report Download Code
export const downloadExcelReport = createAsyncThunk(
  "campaign/downloadExcelReport",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BaseURL}campaign/excel_report/${payload.campaign_id}`, {
        responseType: 'blob', // Important for handling binary data
      });

      // Create a URL for the blob and trigger download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'campaign_excel_report.xlsx'); // Change the file name as needed
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      return {}; // Return empty object since we don’t need the response data
    } catch (error) {
      if (!error || !error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Campaign Report Download Code

const initialError = {
  open: false,
  message: "",
  type: "success",
};

const CampaignSlice = createSlice({
  name: "campaign",
  initialState: {
    campaignNewReport: {},
    campaigns: [],
    singleCampaign: null,
    influencersFromSingleCampaign: [],
    numberOfInfluencersFromCampaign: [],
    loading: false,
    fetchedAllCampaigns: false,
    hashtagHistory: [],
    error: initialError,
    downloadExcelReport_loading: false, // New state for tracking download status
    downloadExcelReport_error: null,
  },
  reducers: {
    removeAlert(state, action) {
      state.error = initialError;
    },
  },
  extraReducers: {
    [getAllCampaigns.pending]: (state, action) => {
      state.loading = true;
      state.fetchedAllCampaigns = false;
    },
    [getAllCampaigns.fulfilled]: (state, action) => {
      state.loading = false;
      state.fetchedAllCampaigns = true;
      state.campaigns = action.payload;
    },
    [getAllCampaigns.rejected]: (state, action) => {
      state.loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [getCampaignDetail.pending]: (state, action) => {
      state.loading = true;
      state.singleCampaign = null;
    },
    [getCampaignDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.singleCampaign = action.payload;
    },
    [getCampaignDetail.rejected]: (state, action) => {
      state.loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [getInfluencersFromSingleCampaign.pending]: (state, action) => {
      state.loading = true;
    },
    [getInfluencersFromSingleCampaign.fulfilled]: (state, action) => {
      state.loading = false;
      state.influencersFromSingleCampaign = action.payload;
    },
    [getInfluencersFromSingleCampaign.rejected]: (state, action) => {
      state.loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [getNoOfInfluencersFromCampaign.pending]: (state, action) => {
      state.loading = true;
    },
    [getNoOfInfluencersFromCampaign.fulfilled]: (state, action) => {
      state.loading = false;
      state.numberOfInfluencersFromCampaign = action.payload;
    },
    [getNoOfInfluencersFromCampaign.rejected]: (state, action) => {
      state.loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [getReportCampaign.pending]: (state, action) => {
      state.loading = true;
    },
    [getReportCampaign.fulfilled]: (state, action) => {
      state.loading = false;
      state.campaignNewReport = action.payload;
    },
    [getReportCampaign.rejected]: (state, action) => {
      state.loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [getHashtagHistory.pending]: (state, action) => {
      state.loading = true;
    },
    [getHashtagHistory.fulfilled]: (state, action) => {
      state.loading = false;
      state.hashtagHistory = action.payload;
    },
    [getHashtagHistory.rejected]: (state, action) => {
      state.loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [getReelsReport.pending]: (state, action) => {
      state.getReelsReport_loading = true;
    },
    [getReelsReport.fulfilled]: (state, action) => {
      state.getReelsReport_loading = false;
      state.reelReport = action.payload;
    },
    [getReelsReport.rejected]: (state, action) => {
      state.getReelsReport_loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [getDemoReport.pending]: (state, action) => {
      state.loading = true;
    },
    [getDemoReport.fulfilled]: (state, action) => {
      state.loading = false;
      state.campaignNewReport = action.payload;
    },
    [getDemoReport.rejected]: (state, action) => {
      state.loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [downloadExcelReport.pending]: (state) => {
      state.downloadExcelReport_loading = true;
      state.downloadExcelReport_error = null;
    },
    [downloadExcelReport.fulfilled]: (state) => {
      state.downloadExcelReport_loading = false;
    },
    [downloadExcelReport.rejected]: (state, action) => {
      state.downloadExcelReport_loading = false;
      state.downloadExcelReport_error = action.payload || "Failed to download report";
      toast.error(
        action.payload.detail || action.payload.msg || "Failed to download report",
        toastOptions
      );
    },
  },
});

export const CampaignActions = CampaignSlice.actions;

export default CampaignSlice.reducer;