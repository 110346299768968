/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import SubscriptionCard from "./SubscriptionCard";
import Checkout from "../../pages/payment/Checkout";
import { Box, Breadcrumbs, Button, CardHeader, Divider, Typography } from "@mui/material";
import Starter from "../../images/subscription/Starter.avif";
import Premium from "../../images/subscription/Premium.avif";
import Advance from "../../images/subscription/Advance.avif";
import { useDispatch, useSelector } from "react-redux";
import { ApplyCoupan, RechargeStripe } from "../../context/slice/SubscriptionSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import AddCoupan from "../../pages/payment/AddCoupan";
import { toast } from "react-toastify";
import { toastOptions } from "../../utils/toastOptions";
import { Link, useNavigate } from "react-router-dom";


const SubscriptionPlan = ({ showAlert, plans = [] }) => {

  const [value, setValue] = useState("1");
  const navigate = useNavigate();
  const [coupan, setCoupan] = useState(null);
  const [applyLoading, setApplyLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("1");
  const [discountAmount, setDiscountAmount] = useState(0);
  const [plan, setPlan] = useState({});

  const { user } = useSelector((store) => store.users);
  const dispatch = useDispatch();

  const paymentGatewayHandler = async (data) => {
    await dispatch(RechargeStripe({ plan: selectedPlan, coupon_code: coupan, ...data }))
      .then(unwrapResult)
      .then((res) => {
        window.open(res.url, "_blank");
      })
      .catch((err) => toast.error(err.msg, toastOptions));
  };

  const applyCoupan = async () => {
    setApplyLoading(true);
    await dispatch(ApplyCoupan({ coupon_code: coupan }))
      .then(unwrapResult)
      .then((res) => {
        toast.success("Coupan applied successfully!!", toastOptions);
        setDiscountAmount(res.discount);
      })
      .catch(() => toast.error("Invalid coupan code", toastOptions))
      .finally(() => {
        setApplyLoading(false);
      });
  };

  useEffect(() => {
    setPlan([...plans]?.find((ele) => ele?.id == selectedPlan));
  }, [selectedPlan, plans]);

  return (
    <>
      {value === "1" && (
        <>
          <Breadcrumbs
            style={{
              margin: "1.5rem .85rem",
              marginBottom: "1%",
              marginLeft: "1.5rem",
            }}
            aria-label="breadcrumb"
          >
            <Link
              style={{ cursor: "pointer" }}
              underline="hover"
              color="inherit"
              to={"/"}
            >
              Home
            </Link>
            <Typography color="text.primary">Subscription Plan</Typography>
          </Breadcrumbs>
          <Box
            style={{
              width: "100%",
              borderTop: "0.3px solid rgba(212, 209, 209, 0.78)",
            }}
          />
          <CardHeader
            subheader="Choose a plan that works best for you."
            title="Subscription"
            sx={{ mb: 0, pb: 0, pt: 2.5 }}
          />
        </>
      )}
      {value === "1" && (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
            width: "100%",
            gridGap: ".8rem",
            margin: "1rem 0",
            // padding: "0 1.75rem",
          }}
        >
          {plans?.map((plan) => (
            <SubscriptionCard
              key={plan.id}
              plan={plan.description}
              influencers={plan.influencers_reports_limit}
              campaign={plan.campaigns_reports_limit}
              price={plan.amount}
              months={plan.validity}
              competitors={plan.competitors_reports_limit}
              competitorCampaigns={plan.competitors_campaign_reports_limit || "N/A"}
              plan_id={plan.id}
              showAlert={showAlert}
              setValue={setValue}
              setSelectedPlan={setSelectedPlan}
            />
          ))}
        </div>
      )}
      {value === "2" && plan?.description?.trim()?.length > 0 && (
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <Checkout
            user={user}
            paymentGatewayHandler={paymentGatewayHandler}
            plan_id={plan.id}
            plan_amount={plan.amount}
            plan_title={plan.description}
          />
          <div style={{ width: "30%" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                boxShadow: "0 5px 10px rgba(0, 0, 0, 0.1)",
                padding: "1rem",
                borderRadius: "1rem",
                marginTop: "1.5rem",
                paddingTop: "2rem",
              }}
            >
              <h3 className="text-xl text-[#333] pb-5 uppercase">Selected Plan</h3>
              <div
                style={{
                  margin: "1rem 0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <img
                  src={
                    plan?.description === "Monthly"
                      ? Starter
                      : plan?.description === "Quarterly"
                        ? Advance
                        : Premium
                  }
                  style={{ width: "200px", height: "175px" }}
                  alt={plan?.description}
                />
                <h2 style={{ fontSize: "1.55rem" }}>{plan?.description}</h2>
              </div>
              <div className="flex flex-col justify-center items-start w-full">
                <h3 className="font-semibold text-xl mb-4">Order Summary</h3>
                <div className="flex w-full flex-row justify-between items-center mb-4">
                  <span className="font-normal text-lg">Plan</span>
                  <span className="font-semibold text-lg">₹ {plan?.amount}</span>
                </div>
                {user.user.country !== "IN" ? (
                  plan?.per ? (
                    <div className="flex w-full flex-row justify-between items-center">
                      <span className="font-normal text-lg">
                        {plan?.tax_type} ({parseInt(plan?.per)}%)
                      </span>
                      <span className="font-medium text-lg">
                        ₹ {parseInt(+plan?.amount * (parseInt(plan?.per) / 100))}
                      </span>
                    </div>
                  ) : null
                ) : (
                  <>
                    {user.user?.city?.toLowerCase() === "maharashtra" ? (
                      <>
                        <div className="flex w-full flex-row justify-between items-center">
                          <span className="font-normal text-lg">GST (9.0 %)</span>
                          <span className="font-medium text-lg">
                            ₹ {+plan?.amount * (9 / 100)}
                          </span>
                        </div>
                        <div className="flex w-full flex-row justify-between items-center">
                          <span className="font-normal text-lg">CGST (9.0 %)</span>
                          <span className="font-medium text-lg">
                            ₹ {+plan?.amount * (9 / 100)}
                          </span>
                        </div>
                      </>
                    ) : (
                      <div className="flex w-full flex-row justify-between items-center">
                        <span className="font-normal text-lg">GST (18.0 %)</span>
                        <span className="font-medium text-lg">
                          ₹ {+plan?.amount * (18 / 100)}
                        </span>
                      </div>
                    )}
                  </>
                )}

                <AddCoupan
                  coupan={coupan}
                  applyCoupan={applyCoupan}
                  setCoupan={setCoupan}
                  loading={applyLoading}
                />
                <div className="flex w-full flex-row justify-between items-center">
                  <span className="font-normal text-lg">Discount</span>
                  <span className="font-medium text-lg">₹ {discountAmount || 0}</span>
                </div>
                <Divider />
                <div className="flex w-full flex-row justify-between items-center mt-8">
                  <span className="font-normal text-lg">Total Amount</span>
                  <span className="font-semibold text-lg">
                    ₹ {user.user.country === "AE" ? plan.amount : plan.amount + +plan.amount * (18 / 100) - discountAmount}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        style={{
          marginTop: ".5rem",
          width: "98%",
          marginBottom: "0",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          flexDirection: "row",
        }}
      >
        {value == 1 && <Box>

          <Button onClick={() => navigate("/")} style={{ marginLeft: "1rem" }} variant="contained">
            Go Back To Home Page
          </Button>
        </Box>}
      </div>
    </>
  );
};

export default SubscriptionPlan;