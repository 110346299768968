/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import {
  Grid as TremorGrid,
  Col,
  Card,
  Text,
  Metric,
  Icon,
  Flex,
  BadgeDelta,
  Title,
  Button as TremorButton,
} from "@tremor/react";
import {
  Box,
  Breadcrumbs,
  Button,
  CardHeader,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import {
  PostInfluencerHistory,
  SingleInfluencertop,
} from "../../../context/slice/influencerSlice";
import SlowMotionVideoOutlinedIcon from '@mui/icons-material/SlowMotionVideoOutlined';
import PagesIcon from '@mui/icons-material/Pages';
import HastagsWordCloud from "../../../components/influencers/stats/HastagsWordCloud";
import InfluencersPosts from "../../../components/campaigns/CampaignDetailPage/InfluencersPosts";
import PaidPatnership from "../../../images/paidPatnership.avif";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { Backdrop } from "@mui/material";
import PDFLoader from "../../../assets/generatingPDFloader.gif";
import FootpryntLogo from "../../../images/FootPryntLowOpacityLogo.png";
import NoReachedPost from "../../../images/404page/NoData.avif";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DefaultUser from "../../../images/defaultUserImg.png";
import Twitter from "../../../images/social_media/twitterLogo.png";
import Youtube from "../../../images/social_media/youtubeLogo.png";
import Instagram from "../../../images/social_media/instagramLogo.jfif";
import InstagramIcon from "@mui/icons-material/Instagram";
import timeAgo from "../../../utils/DateConverter";
import AllPostsDrawer from "../../../components/influencers/overview/AllPaidPromotions";
import {
  CashIcon,
  TicketIcon,
  UserGroupIcon,
  ArrowNarrowRightIcon,
  HeartIcon,
} from "@heroicons/react/solid";
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import InfluencerAnalytics from "../../../components/influencers/analytics/InfluencerAnalytics";
import EngagementRateChart from "../../../components/influencers/analytics/EngagementRateChart";
import FollowersAreaChart from "../../../components/influencers/analytics/FollowersAreaChart";
import TraffivByDevicePieChart from "../../../components/influencers/analytics/TraffivByDevicePieChart";
import GenderFollowersPieChart from "../../../components/influencers/analytics/GenderFollowersPieChart";
import LikesPerformanceChart from "../../../components/influencers/analytics/LikesPerformanceChart";
import FrequencyPostsChart from "../../../components/influencers/analytics/FrequencyPostsChart";
import AgeWiseBreakDown from "../../../components/influencers/analytics/AgeWiseBreakDown";
import DownloadIcon from "@mui/icons-material/Download";
import MarkunreadIcon from "@mui/icons-material/Markunread";
import ChatIcon from "@mui/icons-material/Chat";
import FavoriteIcon from "@mui/icons-material/Favorite";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TwitterIcon from "@mui/icons-material/Twitter";
import nFormatter from "../../../utils/nFormatter";
import SendEmailModal from "../../../components/influencers/SendEmailModal";
import PageLoader from "../../loader/PageLoader";
import OfflineBoltIcon from "@mui/icons-material/OfflineBolt";
import YouTubeIcon from "@mui/icons-material/YouTube";
import ReactToPrint from "react-to-print";
import NoInfluencerFound from "../../../components/influencers/NoInfluencerFound";
import { Document, Page, Text as PDFText, View, StyleSheet } from '@react-pdf/renderer';
import { getTopInfluencersData } from "../../../context/slice/FeaturedContent";
import { historyActions } from "../../../context/slice/HistorySlice";
import useQuery from "../../../hooks/useQuery";


const TopInfluencerDetail = () => {

  // Calculate the current date
  const currentDate = new Date();

  // Subtract 7 days from the current date
  currentDate.setDate(currentDate.getDate() - 7);
  const navigate = useNavigate();
  const [open, setOpenNew] = useState(false);

  // Format the date to a string in the desired format
  const formattedDate = currentDate.toLocaleDateString();
  const { influencerId } = useParams();
  const [openEmail, setOpenEmail] = useState(false);
  const PageRef = useRef();

  const page1 = useRef(null);
  const page2 = useRef(null);
  const page3 = useRef(null);
  const page4 = useRef(null);
  const { influencer, loading, is_invalid_influencer } = useSelector(
    (store) => store.influencers
  );
  const dispatch = useDispatch();
  const [fileLoading, setLoading] = useState(false);

  const query = useQuery();
  const [title, setTitle] = useState();

  const location = useLocation();
  const categoryname = location.pathname.split('&category=')[1];

  useEffect(() => {
    setTitle(query.get("title"));
  }, [useLocation().search]);

  useEffect(() => {
    dispatch(
      historyActions.setHistory({
        URL: location.pathname + location.search,
        name: "topinfluencers",
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      getTopInfluencersData({
        social_media: query.get("social_media"),
        category: query.get("category"),
        country: query.get("country"),
      })
    );
  }, [dispatch]);


  useEffect(() => {
    (async function () {
      try {
        const res = await dispatch(SingleInfluencertop({ id: influencerId })).unwrap();
        await dispatch(
          PostInfluencerHistory({
            type: "search",
            data: {
              influencer_profile_link: res?.profile_link,
              influencer_name: res?.handle,
              follower_count: res?.follower_count,
              date: Date.now(),
            },
          })
        ).unwrap();

        // Check if total posts are undefined and open a modal if necessary
        if (res?.total_posts === undefined) {
          setOpenNew(true);
        }
      } catch (error) {
        console.error("Error fetching influencer details:", error);
      }
    })();
  }, [influencerId, dispatch]);

  const pdfGenerate = async () => {
    setLoading((prev) => !prev);
    const pg1 = page1.current;
    const pg2 = page2.current;
    const pg3 = page3.current;
    const pg4 = page4.current;
    let pages = [pg1, pg2, pg3, pg4];

    const canvas1 = await html2canvas(pages[0], {
      allowTaint: true,
      logging: true,
      letterRendering: 1,
      useCORS: true,
    });
    const canvas2 = await html2canvas(pages[1], {
      allowTaint: true,
      logging: true,
      letterRendering: 1,
      useCORS: true,
    });
    const canvas3 = await html2canvas(pages[2], {
      allowTaint: true,
      logging: true,
      letterRendering: 1,
      useCORS: true,
    });
    const canvas4 = await html2canvas(pages[3], {
      allowTaint: true,
      logging: true,
      letterRendering: 1,
      useCORS: true,
    });
    const imageWidth = 200;
    const imageHeight = (canvas1.height * imageWidth) / canvas1.width;
    const imgdata = canvas1.toDataURL("img/png");
    let doc = new jsPDF("p", "mm", "a4");
    doc.addImage(imgdata, "png", 0, 0, imageWidth, imageHeight);
    doc.setTextColor(150);
    doc.addImage(FootpryntLogo, "png", 10, 100, 180, 50);
    doc.addPage();
    const imageHeight2 = (canvas2.height * imageWidth) / canvas2.width;
    const imgdata2 = canvas2.toDataURL("img/png");
    doc.addImage(imgdata2, "png", 0, 0, imageWidth, imageHeight2);
    doc.setTextColor(150);
    doc.addImage(FootpryntLogo, "png", 10, 100, 180, 50);
    doc.addPage();
    const imageHeight3 = (canvas3.height * imageWidth) / canvas3.width;
    const imgdata3 = canvas3.toDataURL("img/png");
    doc.addImage(imgdata3, "png", 0, 0, imageWidth, imageHeight3);
    doc.setTextColor(150);
    doc.addImage(FootpryntLogo, "png", 10, 100, 180, 50);
    doc.addPage();
    const imageHeight4 = (canvas4.height * imageWidth) / canvas4.width;
    const imgdata4 = canvas4.toDataURL("img/png");
    doc.addImage(imgdata4, "png", 0, 0, imageWidth, imageHeight4);
    doc.setTextColor(150);
    doc.addImage(FootpryntLogo, "png", 10, 100, 180, 50);
    // doc.compress();
    setLoading((prev) => !prev);
    await doc.save(`${influencer?.handle}-${influencerId}.pdf`);

  };

  const widgets = [
    {
      title: "Total Followers",
      metric: nFormatter(influencer?.follower_count | 0) || 0,
      icon: UserGroupIcon,
      color: "indigo",
      rate: influencer?.followers_growth?.percent,
      status: influencer?.followers_growth?.status,
    },
    {
      title: "Total Posts",
      metric: influencer?.total_posts || 0,
      icon: CashIcon,
      color: "fuchsia",
      rate: influencer?.posts_growth?.percent,
      status: influencer?.posts_growth?.status,
    },
    {
      title: "Engagement Rate",
      metric: influencer?.avg_er_till_yesterday?.toFixed(2) || 0 + "%",
      icon: TicketIcon,
      color: "amber",
      rate: influencer?.engagement_rate_growth?.percent,
      status: influencer?.engagement_rate_growth?.status,
    },
  ];

  const posts = [
    {
      title: "Most Liked Post",
      metric: nFormatter(influencer?.most_liked_post?.likes | 0),
      icon: <FavoriteIcon color="error" sx={{ fontSize: "2.5rem" }} />,
      color: "rose",
      link: influencer?.most_liked_post?.post_link,
    },
    {
      title: "Commented Post",
      metric: nFormatter(influencer?.most_commented_post?.comments | 0),
      icon: <ChatIcon sx={{ fontSize: "2.5rem", color: "#22c55e" }} />,
      color: "green",
      link: influencer?.most_commented_post?.post_link,
    },
    {
      title: influencer?.social_media === "twitter" ? "Most Retweeted Post" : "Most Viewed Post",
      metric: influencer?.social_media === "twitter" ? nFormatter(influencer?.most_shared_post?.retweet_count | 0) : nFormatter(influencer?.most_viewed_post?.views | 0),
      icon: influencer?.social_media === "twitter" ? <TwitterIcon color="info" sx={{ fontSize: "2.5rem" }} /> : <VisibilityIcon color="primary" sx={{ fontSize: "2.5rem" }} />,
      color: "blue",
      link: influencer?.social_media === "twitter" ? influencer?.most_shared_post?.post_link : influencer?.most_viewed_post?.post_link,
    },
    {
      title: "No of reels",
      metric: influencer?.account_analytics?.no_of_reels || 0,
      icon: <SlowMotionVideoOutlinedIcon sx={{ fontSize: "2.5rem", color: "#22c55e" }} />,
      color: "blue",
      link: influencer?.displayname + "/reels/",
      showFor: "instagram",
    },
    {
      title: "No of Posts",
      metric: influencer?.total_posts - influencer?.account_analytics?.no_of_reels || 0,
      icon: <PagesIcon sx={{ fontSize: "2.5rem", color: "#22c55e" }} />,
      color: "green",
      link: influencer?.displayname,
      showFor: "instagram",
    },
    {
      title: "Last Post Date",
      metric: influencer?.updated_at ? new Date(influencer?.updated_at).toLocaleDateString() : formattedDate,
      icon: <UpdateOutlinedIcon sx={{ fontSize: "2.5rem", color: "#22c55e" }} />,
      color: "rose",
      link: influencer?.most_commented_post?.post_link,
    },
  ];

  const filteredPosts = posts.filter((post) => {
    if (post.title === "No of reels" && influencer?.social_media !== "instagram") {
      return false;
    }
    if (post.title === "No of Posts" && influencer?.social_media !== "instagram") {
      return false;
    }
    return true;
  });

  const { subscription_history, loading: subscriptionLoading } = useSelector(
    (store) => store.subscription
  );

  const isExpired =
    !subscriptionLoading && (subscription_history?.[0]?.credits_remaining || 0);

  if (loading || subscriptionLoading) {
    return <PageLoader />;
  }

  if (!loading && is_invalid_influencer) {
    return (
      <Box
        component="main"
        sx={{
          alignItems: "center",
          display: "flex",
          flexGrow: 1,
          minHeight: "100%",
        }}
        style={{ margin: "2rem 0", marginTop: "1rem" }}
      >
        <Container maxWidth="md">
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography align="center" color="textPrimary" variant="h3">
              {isExpired == 0
                ? "The Influencer Report that you are looking for is not Accessible"
                : "The Influencer profile/report you are looking for is currently generating..."}
            </Typography>
            <Typography
              sx={{ pb: 0, mb: 0 }}
              align="center"
              color="textPrimary"
              variant="subtitle1"
            >
              {isExpired == 0
                ? "Kindly recharge your plan to view more influencers"
                : "Please wait for a few minites. For now try using the navigation"}
            </Typography>
            <Box sx={{ textAlign: "center" }}>
              <img
                loading="lazy"
                alt="Under development"
                src={NoReachedPost}
                style={{
                  display: "inline-block",
                  maxWidth: "100%",
                  width: 400,
                }}
              />
            </Box>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <RouterLink to={"/influencers"}>
                <Button
                  startIcon={<ArrowBackIcon fontSize="small" />}
                  sx={{ mt: 0.2, mb: 0 }}
                  variant="contained"
                >
                  Go back to Influencers Page
                </Button>
              </RouterLink>
              {isExpired == 0 ? (
                <RouterLink to={"/user-profile?tab=3"}>
                  <Button
                    startIcon={<OfflineBoltIcon fontSize="small" />}
                    sx={{ mt: 0.2, mb: 0, ml: 2 }}
                    variant="contained"
                    color="error"
                  >
                    Recharge Now
                  </Button>
                </RouterLink>
              ) : null}
            </Box>
          </Box>
        </Container>
      </Box>
    );
  }

  return (
    <Box
      component="main"
      sx={{
        // flexGrow: 1,
        py: 2,
      }}
    >
      {fileLoading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <img src={PDFLoader} alt="PDFLoader" style={{ objectFit: "cover" }} />
        </Backdrop>
      )}
      {!loading && influencer && (
        <>
          <Container maxWidth={false} style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex" }}>
              <Breadcrumbs
                style={{
                  margin: "1.5rem .85rem",
                  marginBottom: "0%",
                  marginLeft: "1.5rem",
                }}
                aria-label="breadcrumb"
              >
                <Link
                  style={{ cursor: "pointer" }}
                  underline="hover"
                  color="inherit"
                  to={"/"}
                >
                  Home
                </Link>
                <Link
                  style={{ cursor: "pointer" }}
                  underline="hover"
                  color="inherit"
                  to={"/topinfluencers"}
                >
                  Top Influencers
                </Link>
                <Typography color="text.primary" style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>{categoryname && categoryname !== "null" ? `${categoryname} Influencers` : "Influencers"}</Typography>
                <Typography color="text.primary">Influencer Detail</Typography>
              </Breadcrumbs>
            </div>
            <div className="rightbutton" style={{ marginRight: "21px" }}>
              <NoInfluencerFound
                open={open}
                setOpen={setOpenNew}
                socialMedia=""
                searchInfluencer=""
                setShowSuccessModal={() => { }}
              />
            </div>
          </Container>
          <Document>
            <Page size="A4" style={{ margin: "0px" }}>
              <div ref={PageRef}>
                <SendEmailModal open={openEmail} setOpen={setOpenEmail} />
                <div>
                  <section className="flex flex-col lg:flex-row justify-center items-center p-4 ">
                    <div className="border-gray-200 border-2 p-4 rounded h-[300px] flex flex-col justify-between items-center">
                      <h1 className="text-xl font-medium text-center cursor-pointer text-blue-900 hover:underline">
                        @{influencer?.handle || "Anonymous"}
                      </h1>
                      <div className="relative">
                        <img
                          loading="lazy"
                          className="w-[140px] h=[140px] rounded-full"
                          src={influencer?.profile_link || DefaultUser}
                          alt={influencer?.handle}
                          onError={(e) => {
                            e.currentTarget.src = DefaultUser;
                          }}
                        />
                        {influencer?.social_media === "twitter" && (
                          <img
                            loading="lazy"
                            className="w-[40px] absolute bottom-0 right-0 rounded-full"
                            src={Twitter}
                            alt={"twitter"}
                          />
                        )}
                        {influencer?.social_media === "youtube" && (
                          <img
                            loading="lazy"
                            className="w-[40px] absolute bottom-0 right-0 rounded-full"
                            src={Youtube}
                            alt={"YouTube"}
                          />
                        )}
                        {influencer?.social_media === "instagram" && (
                          <img
                            loading="lazy"
                            className="w-[40px] absolute bottom-0 right-0 rounded-full"
                            src={Instagram}
                            alt={"Instagram"}
                          />
                        )}
                      </div>
                      <div className="flex flex-col justify-center items-center mt-1 w-full">
                        <div className="flex flex-row justify-between items-center w-full">
                          <span className="font-medium text-sm">Category</span>
                          <span className="font-medium text-sm">
                            {influencer?.category || ""}
                          </span>
                        </div>
                        <div className="flex flex-row justify-between items-center w-full">
                          <span className="font-medium text-sm">Location</span>
                          <span className="font-medium text-sm">
                            {influencer?.location || "India"}
                          </span>
                        </div>
                      </div>
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{ mt: 0.5 }}
                        startIcon={
                          influencer?.social_media === "twitter" ? (
                            <TwitterIcon />
                          ) : influencer?.social_media === "instagram" ? (
                            <InstagramIcon />
                          ) : (
                            <YouTubeIcon />
                          )
                        }
                        size="small"
                        href={influencer?.displayname}
                        disabled={!influencer?.displayname}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {influencer?.social_media
                          ? "View Profile on " +
                          influencer?.social_media?.charAt(0).toUpperCase() +
                          influencer?.social_media?.slice(1)
                          : "Not Avaliable"}
                      </Button>
                    </div>
                    <TremorGrid
                      numItems={1}
                      numItemsSm={2}
                      numItemsLg={3}
                      className="gap-2 flex-1 pl-4"
                    >
                      {widgets.map((item) => (
                        <Card
                          key={item.title}
                          decoration="top"
                          decorationColor={item.color}
                        >
                          <Flex
                            justifyContent="between"
                            className="space-x-4 w-full"
                          >
                            <div className="truncate">
                              <Text>{item.title}</Text>
                              <Metric className="truncate">{item.metric}</Metric>
                            </div>
                            <Icon
                              icon={item.icon}
                              variant="light"
                              size="xl"
                              color={item.color}
                            />
                          </Flex>
                          <Flex justifyContent="start" className="space-x-2 mt-4">
                            <BadgeDelta
                              deltaType={
                                item.status === "decreased"
                                  ? "moderateDecrease"
                                  : "moderateIncrease"
                              }
                            />
                            <Flex
                              justifyContent="start"
                              className="space-x-1 truncate"
                            >
                              <Text color="emerald">
                                {item?.rate?.toFixed(2) || 0} %
                              </Text>
                            </Flex>
                          </Flex>
                        </Card>
                      ))}
                      <Flex justifyContent="between" className="space-x-2 w-full">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            width: "250px",
                            border: "1px solid rgb(209 213 219 /1)",
                            borderRadius: "10px",
                            height: "150px",
                            cursor: "pointer",
                          }}
                        >
                          <Title>Export</Title>
                          <ReactToPrint
                            trigger={() =>
                              <button>
                                <DownloadIcon
                                  sx={{ color: "purple", width: "60px", height: "60px" }}
                                />
                              </button>
                            }
                            content={() => PageRef.current}
                          />
                          <button>print</button>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            width: "250px",
                            border: "1px solid rgb(209 213 219 /1)",
                            borderRadius: "10px",
                            height: "150px",
                            cursor: "pointer",
                          }}
                        >
                          <Title>Contact</Title>
                          <MarkunreadIcon
                            sx={{ color: "blue", width: "60px", height: "60px" }}
                          />
                          <TremorButton
                            size="sm"
                            variant="light"
                            iconPosition="right"
                            className="mt-1"
                            onClick={() => setOpenEmail(true)}
                          >
                            E-mail
                          </TremorButton>
                        </div>
                      </Flex>
                      <Card decoration="top" decorationColor={"red"} className="w-full">
                        <Flex justifyContent="between" className="space-x-2 w-full">
                          <div className="truncate">
                            <Text>Avg. Likes</Text>
                            <Metric className="truncate">
                              {nFormatter(influencer?.account_analytics?.avg_likes_received || 0)}
                            </Metric>
                          </div>
                          <Icon
                            icon={HeartIcon}
                            variant="light"
                            size="xl"
                            color={"red"}
                          />
                        </Flex>
                        <Flex justifyContent="start" className="space-x-2 mt-4">
                          <BadgeDelta
                            deltaType={
                              influencer?.avg_likes_rate?.status === "decreased"
                                ? "moderateDecrease"
                                : "moderateIncrease"
                            }
                          />
                          <Flex justifyContent="start" className="space-x-1 truncate">
                            <Text color="emerald">
                              {influencer?.avg_likes_rate?.rate?.toFixed(2) || 0}
                            </Text>
                          </Flex>
                        </Flex>
                      </Card>
                      <Card decoration="top" decorationColor={"blue"}>
                        <Flex justifyContent="between" className="space-x-2 w-full">
                          <div className="truncate">
                            <Text>
                              Avg.{" "}
                              {influencer?.social_media === "twitter"
                                ? "ReTweet"
                                : "Comments"}
                            </Text>
                            <Metric className="truncate">
                              {influencer?.social_media === "twitter"
                                ? nFormatter(
                                  parseInt(
                                    influencer?.account_analytics?.avg_shares | 0
                                  )
                                )
                                : nFormatter(
                                  parseInt(
                                    influencer?.account_analytics?.avg_comments |
                                    0
                                  )
                                )}
                            </Metric>
                          </div>
                          {influencer?.social_media === "twitter" ? (
                            <TwitterIcon color="info" sx={{ fontSize: "3rem" }} />
                          ) : (
                            <ChatIcon color="info" sx={{ fontSize: "3rem" }} />
                          )}
                        </Flex>
                        <Flex justifyContent="start" className="space-x-2 mt-4">
                          <BadgeDelta
                            deltaType={
                              influencer?.avg_comments_rate?.status === "decreased"
                                ? "moderateDecrease"
                                : "moderateIncrease"
                            }
                          />
                          <Flex
                            justifyContent="start"
                            className="space-x-1 truncate"
                          >
                            <Text color="emerald">
                              {influencer?.avg_comments_rate?.rate?.toFixed(2) || 0}
                            </Text>
                          </Flex>
                        </Flex>
                      </Card>
                    </TremorGrid>
                  </section>
                  <TremorGrid
                    numItems={1}
                    numItemsSm={2}
                    numItemsLg={filteredPosts.length <= 4 ? 4 : 6}
                    className="gap-2 flex-1 pl-4"
                  >
                    {filteredPosts.slice(0, 6).map((item) => (
                      <Card
                        className="w-full"
                        key={item.title}
                        decoration="top"
                        decorationColor={item.color}
                      >
                        <Flex justifyContent="between" className="space-x-2 w-full">
                          <div className="truncate">
                            <Text>{item.title}</Text>
                            <Metric style={{ fontSize: "1.5rem" }} className="truncate">
                              {item.metric}
                            </Metric>
                          </div>
                          {item.icon}
                        </Flex>
                        <TremorButton
                          size="sm"
                          variant="light"
                          iconPosition="right"
                          className="mt-1"
                          icon={ArrowNarrowRightIcon}
                        >
                          <a
                            href={item.link?.startsWith("https://") ? item.link : "https://" + item.link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Click Here View Post
                          </a>
                        </TremorButton>
                      </Card>
                    ))}
                  </TremorGrid>
                  <PDFText>
                    <InfluencerAnalytics influencer={influencer} />
                  </PDFText>
                </div>


                <TremorGrid numItemsLg={4} className="mt-6 gap-6 p-4">
                  <Col numColSpan={1} numColSpanLg={2}>
                    <EngagementRateChart data={influencer?.engagement_rate} />
                  </Col>
                  <GenderFollowersPieChart
                    data={influencer?.gender_wise_breakdown?.["M/FRatio"]}
                  />
                  <TraffivByDevicePieChart data={influencer?.trafic_by_device} />
                </TremorGrid>


                <TremorGrid numItemsLg={3} className="mt-6 gap-6 p-4">
                  <Col numColSpan={1} numColSpanLg={2}>
                    <Page>
                      <View>
                        <FrequencyPostsChart data={influencer?.frequency_of_posts} />
                      </View>
                    </Page>
                  </Col>
                  <AgeWiseBreakDown data={influencer?.gender_wise_breakdown} />
                </TremorGrid>

                <Col numColSpan={1} numColSpanLg={2}>
                  <FollowersAreaChart data={influencer?.followers} />
                  <TremorGrid numItemsLg={2} className="mt-6 gap-6 p-4">
                    <View>
                      <HastagsWordCloud
                        hashtags={influencer?.hashtags}
                        handlename={influencer?.handle}
                      />
                    </View>
                    <LikesPerformanceChart data={influencer?.followers} posts={influencer?.category_posts} />
                  </TremorGrid>
                </Col>
                <TremorGrid numItemsLg={2} className="mt-6 gap-6 p-4  w-full">

                </TremorGrid>
                <div>
                  <Grid item lg={12} md={12} xl={9} xs={12}>
                    <CardHeader
                      title={"Latest Paid Promotions"}
                      subheader={"Latest Posts of " + influencer?.handle}
                      action={
                        influencer?.paid_partnership?.length > 10 && (
                          <AllPostsDrawer influencer={influencer} />
                        )
                      }
                    />
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                        width: "100%",
                        padding: "1rem",
                        gridGap: ".85rem",
                      }}
                    >
                      {(influencer?.paid_partnership?.length === 0 ||
                        !influencer?.paid_partnership) && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              marginBottom: "2rem",
                            }}
                          >
                            <img
                              loading="lazy"
                              width={"400px"}
                              src={PaidPatnership}
                              alt="nopaidpatnership"
                            />
                            <p>No Paid Patnerships done by {influencer?.handle}</p>
                          </div>
                        )}
                      {influencer?.paid_partnership
                        ?.slice(0, 10)
                        ?.map((post, i) => {
                          return (
                            <InfluencersPosts
                              image={influencer?.profile_link}
                              key={i + 1}
                              user={influencer?.fullname || influencer?.handle}
                              title={post.title}
                              likes={post.likes}
                              socialMedia={influencer?.social_media}
                              comments={post.comments}
                              views={post.views}
                              shares={post.shares || post.retweet_count}
                              desc={post.post || post.tweet}
                              createdAt={timeAgo(post?.created_at)}
                              hashtags={post?.post
                                ?.split(" ")
                                .filter((h) => h.includes("#"))}
                              link={post?.post_link}
                            />
                          );
                        })}
                    </div>
                  </Grid>
                </div>
              </div>
            </Page>
          </Document>
        </>
      )}
    </Box >
  );
};

export default TopInfluencerDetail;