import React, { useState } from 'react';
import PropTypes from 'prop-types'; 

const ReadMore = ({ text, maxWords }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const words = text?.split(" ") || [];
  const shouldTruncate = words.length > maxWords;

  const displayedText = shouldTruncate && !isExpanded
    ? words.slice(0, maxWords).join(" ") + "..."
    : text;

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <span>
      {displayedText}
      {shouldTruncate && (
        <span
          onClick={toggleReadMore}
          style={{ color: "blue", cursor: "pointer", marginLeft: "5px" }}
        >
          {isExpanded ? "Read Less" : "Read More"}
        </span>
      )}
    </span>
  );
};

ReadMore.propTypes = {
  text: PropTypes.string.isRequired,
  maxWords: PropTypes.number.isRequired,
};

export default ReadMore;
