/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BaseURL } from "../../services/API_services";
import { toast } from "react-toastify";
import { toastOptions } from "../../utils/toastOptions";
import { useSelector } from "react-redux";

export const getDropdownLists = createAsyncThunk(
  "influencers/getDropdownLists",
  async (payload, { rejectWithValue }) => {
    try {
      const res = await axios.get(`${BaseURL}influencer/countries/`);
      return res.data;
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      // ////console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getTopInfluencersData = createAsyncThunk(
  "influencers/getTopInfluencersData",
  async (payload, { rejectWithValue, fulfillWithValue}) => {
    // console.log("Payload1-:", payload);
    try {
      const res = await axios.get(
        `${BaseURL}common/topinfluencers/?social_media=${payload.social_media}&category=${payload.category}&country=${payload.country}&type=${payload.id}`
      );
      return fulfillWithValue(res.data);
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const Categories = createAsyncThunk(
  "influencers/getCategories",
  async (payload, { rejectWithValue, getState }) => {
    try {
      const res = await axios.get(`${BaseURL}influencer/categories/`);
      return res.data;
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      // ////console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const Cities = createAsyncThunk(
  "influencers/getCities",
  async (payload, { rejectWithValue, getState }) => {
    try {
      const res = await axios.get(`${BaseURL}common/cities/`);
      return res.data;
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      // ////console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

const initialError = {
  open: false,
  message: "",
  type: "success",
};

const FeaturedContentSlice = createSlice({
  name: "featuredContent",
  initialState: {
    loading: false,
    topInfluencersData: [],
    dropdown: {},
    categories: [],
    cities: [],
  },
  reducers: {
    removeAlert(state, action) {
      state.error = initialError;
    },
  },
  extraReducers: {
    [getTopInfluencersData.pending]: (state, action) => {
      state.loading = true;
      state.error = initialError;
    },
    [getTopInfluencersData.fulfilled]: (state, action) => {
      state.loading = false;
      state.topInfluencersData = action?.payload;
    },
    [getTopInfluencersData.rejected]: (state, action) => {
      state.loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [getDropdownLists.pending]: (state, action) => {
      state.loading = true;
      state.error = initialError;
    },
    [getDropdownLists.fulfilled]: (state, action) => {
      state.loading = false;
      state.dropdown = action?.payload?.[0];
    },
    [getDropdownLists.rejected]: (state, action) => {
      state.loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [Categories.pending]: (state, action) => {
      state.loading = true;
      state.error = initialError;
    },
    [Categories.fulfilled]: (state, action) => {
      state.loading = false;
      state.categories = action?.payload;
    },
    [Categories.rejected]: (state, action) => {
      state.loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [Cities.pending]: (state, action) => {
      state.loading = true;
      state.error = initialError;
    },
    [Cities.fulfilled]: (state, action) => {
      state.loading = false;
      state.cities = action?.payload?.cities?.reduce((accumulator, current) => {
        if (
          !accumulator.find(
            (item) => item.City.toLowerCase() === current.City.toLowerCase()
          )
        ) {
          accumulator.push(current);
        }
        return accumulator;
      }, []);
    },
    [Cities.rejected]: (state, action) => {
      state.loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
  },
});

export const FeaturedContentActionns = FeaturedContentSlice.actions;

export default FeaturedContentSlice.reducer;
