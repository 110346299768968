/* eslint-disable react/prop-types */
import {
  Avatar,
  Box,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Link as MuiLink,
  TableRow,
  Typography,
  Backdrop,
  CircularProgress,
  Tooltip,
  IconButton,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { Suspense, useState, useContext, useEffect } from "react";
import { Container } from "@mui/system";
import { getInitials } from "../../utils/getInitials";
// import SelectRows from "./info/SelectRows";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import nFormatter from "../../utils/nFormatter";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { influencerStateActions } from "../../context/slice/InfluencerState";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { BaseURL } from "../../services/API_services";
import { toast } from "react-toastify";
const AddInfluencerToCampaign = React.lazy(() =>
  import("./campign/AddInfluencerToCampaign")
);
import CompareIcon from "@mui/icons-material/Compare";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { CompareCartContext } from "../../context/slice/CompareInfluencerSlice";
import NoInfluencers from "../../images/404page/noInfkuencersSelected.avif";
import { historyActions } from "../../context/slice/HistorySlice";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  addFavouriteInfluencer,
  getAllFavInfluencers,
  removeInfluencerFromFavourite,
} from "../../context/slice/FavouriteInfluencerSlice";
import { toastOptions } from "../../utils/toastOptions";

const TableHeadNames = [
  "Profile",
  "Category",
  "Handle name",
  "Followers",
  "Avg Views",
  "Region",
  "Actions",
];

export const InfluencersResults = ({ influencers, count, searchUser }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ctx = useContext(CompareCartContext);
  const { page, record } = useSelector((store) => store.influencersState);
  const [modal, setOpenCampaignModal] = useState(false);
  const [influencer, setInfluencer] = useState({});
  const { user } = useSelector((store) => store.users);
  const { favInfluencers } = useSelector((store) => store.favouriteInfluencer);

  const addToFavList = (handlename) => {
    dispatch(
      addFavouriteInfluencer({
        user: user?.user?.id,
        influencer_handle_name: handlename,
      })
    )
      .then(unwrapResult)
      .then(() => {
        dispatch(getAllFavInfluencers({ id: user.user.id }));
      });
  };

  const removeFromFavList = (id) => {
    dispatch(removeInfluencerFromFavourite(id))
      .then(unwrapResult)
      .then(() => {
        dispatch(getAllFavInfluencers({ id: user.user.id }));
      });
  };

  const addToCompareCartHandler = (influencer) => {
    const data = {
      id: influencer?.influencer_id,
      name: influencer?.influencer_name,
      handlename: influencer?.influencer_handle_name,
      social_media: influencer?.influencer_social_media,
    }
    ctx.addToCart(data);
  };
  const removeFromCompareCartHandler = (id) => {
    ctx.removeOneFromCart(id);
  };

  const openCampaignModal = async (influencer) => {
    try {
      const res = await axios.get(`${BaseURL}campaign/groups/`);
      if (res?.data?.length === 0) {
        navigate("/campaign");
        toast.info("Please Create a campaign to add an influencer", toastOptions);
      } else {
        setOpenCampaignModal(true);
        setInfluencer(influencer);
      }
    } catch (error) {
      toast.error(
        error.detail || error.msg || "Server error",
        toastOptions
      );
    }
  };

  useEffect(() => {
    dispatch(
      historyActions.setHistory({ URL: location.pathname, name: "influencers" })
    );
  }, []);

  return (
    <div
      className="influencer_container__results"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <TableContainer component={Paper}>
        <Table size="small" sx={{ overflowX: "scroll" }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {TableHeadNames?.map((name) => {
                return (
                  <TableCell
                    style={{
                      fontWeight: "700",
                      color: "gray",
                      width: "90px",
                      textAlign: "start",
                    }}
                    key={name}
                  >
                    {name}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {influencers
              ?.map((customer, i) => {
                const favItem = favInfluencers?.find(
                  (inf) =>
                    inf?.influencer_handle_name ===
                    customer?.influencer_handle_name
                );
                const isCompared = ctx.items.find(
                  (inf) => inf.id == customer.influencer_id
                );
                return (
                  <TableRow
                    key={customer.influencer_id}
                    hover
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      navigate(`/influencers/${customer.influencer_id}`)
                    }
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell style={{ width: "120px" }}>
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                          maxWidth: "250px",
                          minWidth: "200px",
                        }}
                      >
                        {customer?.influencer_profile_link ? (
                          <img
                            loading="lazy"
                            style={{
                              marginRight: ".5rem",
                              borderRadius: "50%"
                            }}
                            src={customer.influencer_profile_link}
                            alt={customer.influencer_name}
                            width={40}
                          />
                        ) : (
                          <Avatar src={customer.avatarUrl} sx={{ mr: 2 }}>
                            {getInitials(customer.influencer_name)}
                          </Avatar>
                        )}
                        <Typography color="textPrimary" variant="body1">
                          {customer.influencer_name}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell style={{ width: "120px" }}>
                      {customer.category || customer.category_ai}
                    </TableCell>
                    <TableCell style={{ width: "120px" }}>
                      <MuiLink
                        style={{ textDecoration: "none" }}
                        variant="subtitle2"
                      >
                        @{customer.influencer_handle_name}
                      </MuiLink>
                    </TableCell>
                    <TableCell style={{ width: "120px" }}>
                      {nFormatter(customer.follower_count)}
                    </TableCell>
                    {/* Avg View */}
                    <TableCell>
                      {customer?.avg_views ? customer?.avg_views : "N/A"}
                    </TableCell>
                    {/* Avg View */}
                    <TableCell style={{ width: "120px" }}>
                      {customer?.influencer_country
                        ? customer?.influencer_country
                        : "India"}
                    </TableCell>
                    <TableCell
                      style={{
                        width: "170px",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      {favItem ? (
                        <Tooltip title="Fav">
                          <IconButton
                            onClick={() =>
                              removeFromFavList(favItem.fav_influencer_id)
                            }
                          >
                            <FavoriteIcon style={{ color: "red" }} />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Fav">
                          <IconButton
                            onClick={() =>
                              addToFavList(customer?.influencer_handle_name)
                            }
                          >
                            <FavoriteBorderIcon style={{ color: "darkred" }} />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip title="Add to Campaign">
                        <IconButton
                          sx={{ ml: 1.5 }}
                          onClick={() => openCampaignModal(customer)}
                        >
                          <GroupAddIcon />
                        </IconButton>
                      </Tooltip>
                      {!isCompared ? (
                        <Tooltip title="Compare">
                          <IconButton
                            sx={{ ml: 1.5 }}
                            onClick={() => addToCompareCartHandler(customer)}
                            id={"cmp" + i}
                          >
                            <CompareIcon />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Remove from Compared List">
                          <IconButton
                            sx={{ ml: 1.5 }}
                            onClick={() =>
                              removeFromCompareCartHandler(
                                customer.influencer_id
                              )
                            }
                          >
                            <HighlightOffIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        {influencers.length === 0 && searchUser.length === 0 && (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              margin: "1rem 0",
            }}
          >
            <img
              loading="lazy"
              src={NoInfluencers}
              alt="NoInfluencers"
              style={{ width: "300px" }}
            />
            <Typography sx={{ fontWeight: "500", fontSize: "1rem" }}>
              Please Updated Your Country
            </Typography>
            <Link
              style={{ cursor: "pointer" }}
              underline="hover"
              color="inherit"
              to={"/user-profile?tab=0"}
            >
              Profile Page
            </Link>
          </Box>
        )}
        {count > record && influencers.length > 0 && (
          <Container
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row-reverse",
              alignItems: "center",
            }}
          >
            {/* <SelectRows /> */}
            <Pagination
              style={{
                display: "flex",
                flex: "1",
                justifyContent: "center",
                marginTop: "1rem",
              }}
              color="primary"
              count={Math.ceil(count / record)}
              page={page}
              onChange={(e, value) => {
                dispatch(influencerStateActions.setPage(value));
              }}
            />
          </Container>
        )}
      </TableContainer>
      <Suspense
        fallback={
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
          >
            <CircularProgress color="primary" />
          </Backdrop>
        }
      >
        {modal && (
          <AddInfluencerToCampaign
            open={modal}
            setOpen={setOpenCampaignModal}
            influencer={influencer}
          />
        )}
      </Suspense>
    </div>
  );
};