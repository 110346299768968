import React from 'react';
import GoogleCalendarImg from "../assets/google-calendar.gif"; // Adjust the path as needed

const GoogleCalendar = () => {
    // Replace with your actual calendar embed link
    const calendarSrc = "https://calendar.google.com/calendar/embed?src=&ctz=America%2FNew_York";

    const containerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        marginTop: '20px',
    };

    const imageContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginRight: '55px',
        marginTop: '60px'
    };

    const imageStyle = {
        maxWidth: '500px',
        height: 'auto',
    };

    const captionStyle = {
        textAlign: 'center',
        marginTop: '10px',
        fontSize: '30px',
        color: '#666',
    };

    const calendarContainerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: "20px",
        fontSize: "50px"
    };


    return (
        <div style={containerStyle}>
            <div style={imageContainerStyle}>
                <img
                    src={GoogleCalendarImg}
                    alt="Google Calendar Icon Concept"
                    style={imageStyle}
                />
                <p style={captionStyle}>Google Calendar Icon Concept<br />By Guus Apeldoorn</p>
            </div>
            <div style={calendarContainerStyle}>
                <iframe
                    src={calendarSrc}
                    style={{ border: 0 }}
                    width="800"
                    height="600"
                    title="Google Calendar"
                ></iframe>
            </div>
        </div>
    );
};

export default GoogleCalendar;
