
/* eslint-disable react/prop-types */
import { Box, MenuItem, MenuList, Popover, SwipeableDrawer } from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import React, { useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import "../../styles/global/sidebar.css";
import "../../styles/global/sidebarOpen.css";
import InsertChartOutlinedRoundedIcon from "@mui/icons-material/InsertChartOutlinedRounded";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import StarsIcon from "@mui/icons-material/Stars";
import PortraitIcon from '@mui/icons-material/Portrait';
import EnergySavingsLeafIcon from "@mui/icons-material/EnergySavingsLeaf";
import TopSidebar from "./TopSidebar";
import Dropdown from "./Dropdown";



const race = ['MyProfile', 'Notifications', 'Favourite Influencers', 'Subscription', 'Accessed Reports', 'Transactions',];


const SideBarTabs = [
  {
    href: "/",
    icon: <InsertChartOutlinedRoundedIcon />,
    text: "Dashboard",
  },
  {
    href: "/influencers",
    icon: <PeopleAltOutlinedIcon />,
    text: "Influencers",
  },
  {
    href: "/competitor-analysis",
    icon: <EnergySavingsLeafIcon />,
    text: "Competitors",
  },
  {
    href: "/campaign",
    icon: <Diversity3Icon />,
    text: "Campaign",
  },
  {
    href: "/topinfluencers",
    icon: <StarsIcon />,
    text: "Top Influencers",
  },
];

const AdminSideBarTabs = [
  {
    href: "/admin-panel/latest-users",
    text: "Latest Users",
  },
  {
    href: "/admin-panel/flag",
    text: "Feature Flags",
  },
  {
    href: "/admin-panel/role",
    text: "Admin Role",
  },
];




export const DashboardSidebar = (props) => {
  const { open, onClose, onOpen } = props;
  const MediaQuery = useMediaQuery("(min-width:950px)");
  const [togleSubMenu, setToggleSubMenu] = useState();
  const [toggleDrawerSubMenu, setToggleDrawerSubMenu] = useState();
  const [mouseHovered, setmouseHovered] = useState();
  const { user } = useSelector((store) => store.users);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();


  const handleDropdownSelect = (selectedItem) => {
    switch (selectedItem) {
      case 'MyProfile':
        navigate("/user-profile?tab=0");
        break;
      case 'Notifications':
        navigate("/user-notification");
        break;
      case 'Favourite Influencers':
        navigate("/user-influencers");
        break;
      case 'Subscription':
        navigate("/user-profile?tab=3");
        break;
      case 'Accessed Reports':
        navigate("/user-access-resports");
        break;
      case 'Transactions':
        navigate("/user-transactions");
        break;
      default:
        break;
    }
  };

  const handleProfileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileClose = () => {
    setAnchorEl(null);
  };

  function viewMyProfileHandler() {
    navigate("/user-profile?tab=0");
    handleProfileClose();
  }

  function viewMyNotificationHandler() {
    navigate("/user-notification")
    handleProfileClose();
  }

  function viewMyInfluencersHandler() {
    navigate("/user-influencers")
    handleProfileClose();
  }

  function viewMySubscriptionHandler() {
    navigate("/user-profile?tab=3")
    handleProfileClose();
  }

  function viewMyAccessedReportsHandler() {
    navigate("/user-access-resports")
    handleProfileClose();
  }

  function viewMyTransactionsHandler() {
    navigate("/user-transactions")
    handleProfileClose();
  }


  const onMouseOverHandlerSubMenu = () => {
    if (mouseHovered === true) {
      setToggleSubMenu(true);
      setmouseHovered(true);
    }
  };
  const onMouseOvtHandlerSubMenu = () => {
    setToggleSubMenu(false);
    setmouseHovered(false);
  };

  const onMouseOverHandlerAdminSubMenu = () => {
    setToggleSubMenu(true);
    setmouseHovered(true);
  };

  const ootMouseOverHandlerAdminSubMenu = () => {
    setToggleSubMenu(false);
    setTimeout(() => {
      if (!togleSubMenu) {
        setmouseHovered(false);
      }
    }, 1000);
  };

  const content1 = (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
        id="naviagtion-btns"
      >
        <TopSidebar open={open} />
        <ul className="sidebar__menu">
          {SideBarTabs.map((item) => {
            return (
              <li className="sidebar__menu-list" key={item.text}>
                <NavLink
                  to={item.href}
                  style={({ isActive }) => ({
                    background: isActive && "#1111",
                  })}
                >
                  <div>
                    <Tooltip title={item.text} placement="right">
                      <IconButton>{item.icon}</IconButton>
                    </Tooltip>
                  </div>
                </NavLink>
              </li>
            );
          })}
          <li className="sidebar__menu-list">
            <div onClick={handleProfileClick}>
              <Tooltip title="My Profile" placement="right">
                <IconButton>
                  <PortraitIcon />
                </IconButton>
              </Tooltip>
            </div>
            <Popover
              anchorEl={anchorEl}
              anchorOrigin={{
                horizontal: 'right',
                vertical: 'top',
              }}
              onClose={handleProfileClose}
              open={Boolean(anchorEl)}
              PaperProps={{
                sx: { width: '200px' },
              }}
              style={{ position: "absolute", top: "8%", left: "-2%" }}
            >
              <MenuList
                disablePadding
                sx={{
                  '& > *': {
                    '&:first-of-type': {
                      borderTopColor: 'divider',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1px',
                    },
                    padding: '12px 16px',
                  },
                }}
              >
                <MenuItem onClick={viewMyProfileHandler}>
                  My Profile
                </MenuItem>
                <MenuItem onClick={viewMyNotificationHandler}>
                  Notifications
                </MenuItem>
                <MenuItem onClick={viewMyInfluencersHandler}>
                  Favourite Influencers
                </MenuItem>
                <MenuItem onClick={viewMySubscriptionHandler}>
                  Subscription
                </MenuItem>
                <MenuItem onClick={viewMyAccessedReportsHandler}>
                  Accessed Reports
                </MenuItem>
                <MenuItem onClick={viewMyTransactionsHandler}>
                  Transactions
                </MenuItem>
              </MenuList>

            </Popover>
          </li>
          {user?.user?.is_admin && (
            <li className="sidebar__menu-list">
              <Link
                to="#"
                onMouseOver={onMouseOverHandlerAdminSubMenu}
                onMouseOut={ootMouseOverHandlerAdminSubMenu}
              >
                <div>
                  <Tooltip title="Admin Panel" placement="bottom">
                    <IconButton>
                      <AdminPanelSettingsOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </Link>
            </li>
          )}
          {user?.user?.is_admin && (
            <ul
              className={
                togleSubMenu && mouseHovered && !open
                  ? "sub__menu-admin open"
                  : "sub__menu-admin"
              }
              onMouseOver={onMouseOverHandlerSubMenu}
              onMouseOut={onMouseOvtHandlerSubMenu}
            >
              {AdminSideBarTabs.map((item) => {
                return (
                  <li
                    key={item.href}
                    className="sub__menu-list"
                    onClick={() => setToggleSubMenu(false)}
                  >
                    <NavLink to={item.href}>{item.text}</NavLink>
                  </li>
                );
              })}
            </ul>
          )}
        </ul>
      </Box>
    </div>
  );

  const content2 = (
    <div onClick={onClose} onKeyDown={onClose}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <TopSidebar open={open} />
        <ul className="sidebar__menubar">
          <h2
            className="sidebar__menubar-title"
            style={{ marginBottom: ".4rem" }}
          >
            APPS
          </h2>
          {SideBarTabs.map((item) => {
            return (
              <li className="sidebar__menubar-list" key={item.text}>
                <NavLink
                  to={item.href}
                  onClick={() => onClose()}
                  style={({ isActive }) => ({
                    background: isActive && "#1111",
                  })}
                >
                  <div>
                    {item.icon}
                    {open && <span>{item.text}</span>}
                  </div>
                </NavLink>
              </li>
            );
          })}
          {/* <Dropdown items={race} /> */}
          <li className="sidebar__menu-list">
            <Dropdown items={race} onSelect={handleDropdownSelect} />
          </li>
          {user?.user?.is_admin && (
            <>
              <h2 className="sidebar__menubar-title">ADMIN</h2>
              <li className="sidebar__menubar-list">
                <Link
                  to="#"
                  onMouseOver={() => setToggleSubMenu(true)}
                  onMouseOut={() => setToggleSubMenu(false)}
                  onClick={() => setToggleDrawerSubMenu((prev) => !prev)}
                >
                  <div>
                    <AdminPanelSettingsOutlinedIcon />
                    {open && <span>Admin Panel</span>}
                  </div>
                  <ExpandMore className={toggleDrawerSubMenu && "open"} />
                </Link>
              </li>
            </>
          )}
          {user?.user?.is_admin && (
            <div
              className={
                toggleDrawerSubMenu && open
                  ? "sub__menubar-admin open"
                  : "sub__menubar-admin"
              }
            >
              {AdminSideBarTabs.map((item) => {
                return (
                  <li
                    key={item.href}
                    className="sub__menubar-list"
                    onClick={() => onClose()}
                  >
                    <NavLink
                      to={item.href}
                      style={({ isActive }) => ({
                        background: isActive && "#1111",
                      })}
                    >
                      {item.text}
                    </NavLink>
                  </li>
                );
              })}
            </div>
          )}
        </ul>
      </Box>
    </div>
  );

  return (
    <>
      {!open && MediaQuery ? (
        <div
          style={{
            position: "fixed",
            top: "0%",
            left: "0%",
            width: "80px",
            borderRight: ".3px solid rgb(235, 234, 234)",
            minHeight: "100vh",
            backgroundColor: "rgba(243,244,246,.95)",
          }}
        >
          {content1}
        </div>
      ) : (
        open && (
          <SwipeableDrawer
            anchor="left"
            onClose={onClose}
            open={open}
            PaperProps={{
              sx: {
                backgroundColor: "neutral.100",
                color: "#FFFFFF",
                width: 280,
                overflowX: "hidden",
                minHeight: "100vh",
              },
            }}
            onOpen={onOpen}
            sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
          >
            {content2}
          </SwipeableDrawer>
        )
      )}
    </>
  );
};