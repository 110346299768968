import React, { Fragment } from 'react'
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";
import { Alert, Breadcrumbs, Button, CardHeader, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import TransactionTable from '../../components/profile/TransactionTable';
import NoTransactions from "../../images/paidPatnership.avif"
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import SubscriptionHistoryTable from '../../components/profile/SubscriptionTable';
import useQuery from '../../hooks/useQuery';
import RequestAnotherModal from '../../components/profile/RequestAnotherModal';
import { Link } from 'react-router-dom';

const UserTransactions = () => {

    const query = useQuery();
    const [showAlert, setShowAlert] = React.useState(false);
    const [reqModalOpen, setReqModalOpen] = React.useState(false);
    const tab =
        Number(query.get("tab")) > 4 || Number(query.get("tab")) < 0
            ? 0
            : Number(query.get("tab"));
    const [setValue] = React.useState(tab || 0);
    const {
        subscription_history,
        loading,
        transactions,
        t_loading,
    } = useSelector((store) => store.subscription);

    return (
        <div>
            <div style={{ display: "flex" }}>
                <Breadcrumbs
                    style={{
                        margin: "1.5rem .85rem",
                        marginBottom: "1%",
                        marginLeft: "1.5rem",
                    }}
                    aria-label="breadcrumb"
                >
                    <Link
                        style={{ cursor: "pointer" }}
                        underline="hover"
                        color="inherit"
                        to={"/"}
                    >
                        Home
                    </Link>
                    <Typography color="text.primary">Transactions</Typography>
                </Breadcrumbs>
            </div>
            <Box
                style={{
                    width: "100%",
                    borderTop: "0.3px solid rgba(212, 209, 209, 0.78)",
                }}
            />
            <Box
                sx={{
                    bgcolor: "background.paper",
                    mt: 1,
                    width: "100%",
                }}
            >
                <Tabs
                    // value={value}
                    // onChange={handleChange}
                    variant="scrollable"
                    aria-label="scrollable force auto tabs example"
                >

                    {/* <Tab label="Transactions" /> */}
                </Tabs>
            </Box>
            <div>
                <CardHeader
                    subheader="View all your transctions"
                    title="Latest Transactions"
                    sx={{ mb: 0, pb: 0, pt: 2.5 }}
                />
                {t_loading && (
                    <div
                        role="status"
                        className=" w-full h-[50vh] flex justify-center items-center "
                    >
                        <svg
                            aria-hidden="true"
                            className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                            />
                            <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                            />
                        </svg>
                        <span className="sr-only">Loading...</span>
                    </div>
                )}{" "}
                {!t_loading && transactions && transactions.length > 0 && (
                    <TransactionTable transactions={transactions} />
                )}
                {!t_loading && transactions?.length === 0 && (
                    <div className="flex flex-col justify-center items-center w-full">
                        <img
                            loading="lazy"
                            src={NoTransactions}
                            alt="notransactions"
                            className="w-2/5"
                        />
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-around",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            <Typography style={{ fontSize: "1.5rem", textAlign: "center" }}>
                                Sorry!! You&apos;ve not made any transactions so far
                            </Typography>
                        </div>
                    </div>
                )}
                {!loading && subscription_history?.length > 0 && (
                    <Fragment>
                        {showAlert && (
                            <Alert
                                onClose={() => setShowAlert(false)}
                                severity="success"
                                sx={{ width: "98%", mt: 2 }}
                            >
                                Congratulations!! Your{" "}
                                {subscription_history?.[0]?.description} Plan is currently
                                active.
                            </Alert>
                        )}
                        <CardHeader
                            subheader={"View all your plans that yor bought."}
                            title="Subscription History"
                            sx={{ mb: 0, pb: 0, pt: 2.5, width: "100%" }}
                            action={
                                <Button
                                    style={{ marginTop: ".65rem" }}
                                    variant="contained"
                                    startIcon={<GroupAddIcon fontSize="small" />}
                                    size="small"
                                    onClick={() => setReqModalOpen((prev) => !prev)}
                                >
                                    Request Other Plan
                                </Button>
                            }
                        />
                        <SubscriptionHistoryTable subscription={subscription_history} setTab={setValue} />
                        <RequestAnotherModal
                            open={reqModalOpen}
                            setOpen={setReqModalOpen}
                        />
                    </Fragment>
                )}
            </div>
        </div>
    )
}

export default UserTransactions
