/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import {
  Grid as TremorGrid,
  Card,
  Text,
  Metric,
  Icon,
  Flex,
} from "@tremor/react";
import { UserGroupIcon, StarIcon, UsersIcon } from "@heroicons/react/solid";
import { InfluencersResults } from "../influencers/InfluencersResult";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CompetitorTable from "../../pages/user/CompetitorTable";
import { deleteCompetitor } from "../../context/slice/userSlice";
import { useDispatch, useSelector } from "react-redux";
import EditCompetitorModal from "../competitor/edit/EditCompetitorModal";
import CampaignTable from "../campaigns/CampaignTable";
import { getNoOfInfluencersFromCampaign } from "../../context/slice/CampaignSlice";

const SubscriptionStatus = ({ subscriptionStatus }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editCompetitor, setEditCompetitor] = useState({});
  const { numberOfInfluencersFromCampaign } = useSelector(
    (store) => store.campaign
  );
  const widgets = [
    {
      title: "Influencer Reports remaining",
      metric: subscriptionStatus?.influencers_reports_remaining || 0,
      icon: UsersIcon,
      color: "indigo",
      href: "/influencers",
    },
    {
      title: "Competitor Reports remaining",
      metric: subscriptionStatus?.competitors_reports_remaining || 0,
      icon: StarIcon,
      color: "red",
      href: "/competitor-analysis",
    },
    {
      title: "Campaign Reports remaining",
      metric: subscriptionStatus?.campaigns_reports_remaining || 0,
      icon: UserGroupIcon,
      color: "green",
      href: "/campaign",
    },
  ];
  const deleteCompetitorHnadler = (id) => {
    dispatch(deleteCompetitor(id));
  };
  useEffect(() => {
    dispatch(getNoOfInfluencersFromCampaign());
  }, []);
  return (
    <div>
      <EditCompetitorModal
        editCompetitor={editCompetitor}
        setOpen={setEditModalOpen}
        open={editModalOpen}
      />
      <TremorGrid
        numItems={1}
        numItemsSm={2}
        numItemsLg={3}
        className="gap-2 flex-1 pl-4 mt-8"
      >
        {widgets.map((item) => (
          <Card
            onClick={() => {
              navigate(item.href);
            }}
            key={item.title}
            decoration="top"
            decorationColor={item.color}
            className=" cursor-pointer hover:bg-slate-50"
          >
            <Flex justifyContent="between" className="space-x-4 w-full">
              <div className="truncate">
                <Text>{item.title}</Text>
                <Metric className="truncate">{item.metric}</Metric>
              </div>
              <Icon
                icon={item.icon}
                variant="light"
                size="xl"
                color={item.color}
              />
            </Flex>
          </Card>
        ))}
      </TremorGrid>
      <Typography variant="h2" sx={{ mt: 3, ml: 2, fontSize: "1.35rem" }}>
        Influencers History
      </Typography>
      <Box sx={{ m: 2, mt: 4 }}>
        <InfluencersResults
          influencers={subscriptionStatus?.influencers_accessed?.[0] || []}
          count={subscriptionStatus?.influencers_accessed?.[0]?.length || 0}
          searchUser={""}
        />
      </Box>
      <Typography variant="h2" sx={{ mt: 3, ml: 2, fontSize: "1.35rem", fontWeight: "bold", color: "#333" }}>
        Competitor History
      </Typography>
      <Box sx={{ m: 2, mt: 1 }}>
        {subscriptionStatus?.competitors_accessed?.[0]?.length > 0 ? (
          <CompetitorTable
            competitors={subscriptionStatus?.competitors_accessed?.[0] || []}
            setEditModalOpen={setEditModalOpen}
            deleteCompetitorHnadler={deleteCompetitorHnadler}
            setEditCompetitor={setEditCompetitor}
          />
        ) : (
          <Typography variant="h3" sx={{ color: "#999", fontSize: "1.35rem", fontStyle: "italic" }}>
            No competitor history found.
          </Typography>
        )}
      </Box>

      <Typography variant="h2" sx={{ mt: 3, ml: 2, fontSize: "1.35rem", fontWeight: "bold", color: "#333" }}>
        Campaign History
      </Typography>
      <Box sx={{ m: 2, mt: 1 }}>
        {subscriptionStatus?.campaigns_accessed?.[0]?.length > 0 ? (
          <CampaignTable
            campaigns={subscriptionStatus?.campaigns_accessed?.[0]}
            searchProduct={""}
            numberOfInfluencersFromCampaign={numberOfInfluencersFromCampaign}
          />
        ) : (
          <Typography variant="h3" sx={{ color: "#999", fontSize: "1.35rem", fontStyle: "italic" }}>
            No Campaign history found.
          </Typography>
        )}
      </Box>

      <div
        style={{
          marginTop: ".5rem",
          width: "98%",
          marginBottom: "0",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          flexDirection: "row",
        }}
      >

      </div>
    </div>
  );
};

export default SubscriptionStatus;