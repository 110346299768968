
// firebase.config.js
import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
  TwitterAuthProvider
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyC7Mxoob3gKMJKS--rdQnIuGij9JoAt-HE",
  authDomain: "footpryntpride.firebaseapp.com",
  databaseURL: "https://footpryntpride.firebaseio.com",
  projectId: "footpryntpride",
  storageBucket: "footpryntpride.appspot.com",
  messagingSenderId: "687976242948",
  appId: "1:687976242948:web:eca5407a488bac1b34fa51",
  measurementId: "G-BWTHW9ZHMG"
};

// Microsoft Authentication Config
export const msalConfig = {
  auth: {
    clientId: '9bfff424-7b8a-4b1b-a0c1-14e4fbe089ad',
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: 'http://localhost:3000'
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  }
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firebaseAuth = getAuth(app);
const GoogleProvider = new GoogleAuthProvider();
const FacebookProvider = new FacebookAuthProvider();
const TwitterProvider = new TwitterAuthProvider();

export { firebaseConfig, firebaseAuth, GoogleProvider, FacebookProvider, TwitterProvider };
