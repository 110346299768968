/* eslint-disable react/prop-types */
import React, { Fragment, useState } from "react";
import {
  Button,
  Card,
  Table,
  TableHead,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
  Title,
  Flex,
  Badge,
} from "@tremor/react";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowsExpandIcon } from "@heroicons/react/outline";
import { useNavigate } from "react-router-dom";
import NoReports from "../../images/404page/emptyCampaign.webp";
import { IconButton, Typography, useTheme } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";

export default function RecentlyAccessedHashtagReports({ hashtags, loading }) {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  // Sort hashtags in descending order by `campaign_identify_name`
  const sortedHashtags = [...hashtags].sort((a, b) =>
    b.campaign_identify_name.localeCompare(a.campaign_identify_name)
  );

  return (
    <>
      <Card className="relative mx-auto h-[25.5rem] overflow-hidden">
        <Flex justifyContent="start" className="space-x-2">
          <Title>My HashTags</Title>
          <Badge color="gray">{loading ? "Loading..." : sortedHashtags.length}</Badge>
        </Flex>
        {loading && (
          <div
            role="status"
            className="w-full h-full flex justify-center items-center"
          >
            <svg
              aria-hidden="true"
              className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        )}
        {!loading && sortedHashtags.length > 0 && (
          <Table>
            <TableHead>
              <TableRow>
                <TableHeaderCell>Hashtag</TableHeaderCell>
                <TableHeaderCell >Social Media</TableHeaderCell>
                <TableHeaderCell >Created At</TableHeaderCell>
                <TableHeaderCell className="text-right">View</TableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedHashtags.map((item) => (
                <TableRow key={item.campaign_identify_name}>
                  <TableCell>{item.campaign_identify_name}</TableCell>
                  <TableCell>
                    <Badge color="pink" >{item.campaign_social_media}</Badge>
                  </TableCell>
                  <TableCell>
                    <Badge color="pink" >{item.created_at}</Badge>
                  </TableCell>
                  <TableCell className="text-right">
                    <IconButton
                      size="xs"
                      variant="secondary"
                      color="gray"
                      onClick={() => navigate(`/hashtag/${item?.id}`)}
                    >
                      <VisibilityIcon color="info" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
        {!loading && sortedHashtags.length === 0 && (
          <div
            style={{
              marginTop: ".5rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <img
              loading="lazy"
              src={NoReports}
              alt="NoReports"
              style={{ width: "250px" }}
            />
            <Typography style={{ color: theme.palette.grey[500] }}>
              Sorry!! You have not made any custom Hashtag request
            </Typography>
          </div>
        )}
        {!loading && sortedHashtags.length > 4 && (
          <div className="inset-x-0 bottom-0 flex justify-center bg-gradient-to-t from-white pt-12 pb-8 absolute rounded-b-lg">
            <Button
              icon={ArrowsExpandIcon}
              className="bg-white shadow-md border-gray-200 text-gray-500 hover:bg-gray-50 hover:border-gray-300"
              onClick={openModal}
              disabled={loading}
            >
              Show more
            </Button>
          </div>
        )}
      </Card>
      {!loading && sortedHashtags.length > 0 && (
        <Transition appear show={isOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50" onClose={closeModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900 bg-opacity-25" />
            </Transition.Child>
            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-xl transform overflow-hidden ring-tremor bg-white
                                      p-6 text-left align-middle shadow-tremor transition-all rounded-xl"
                  >
                    <div className="relative mt-3">
                      <Table className="h-[450px]">
                        <TableHead>
                          <TableRow>
                            <TableHeaderCell className="bg-white">Hashtag</TableHeaderCell>
                            <TableHeaderCell className="bg-white text-right">Social Media</TableHeaderCell>
                            <TableHeaderCell className="bg-white text-right">Created At</TableHeaderCell>
                            <TableHeaderCell className="text-right">View</TableHeaderCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {sortedHashtags.map((item) => (
                            <TableRow key={item.campaign_identify_name}>
                              <TableCell>{item.campaign_identify_name}</TableCell>
                              <TableCell className="text-right">
                                <Badge color="pink" size="xs">{item.campaign_social_media}</Badge>
                              </TableCell>
                              <TableCell className="text-right">
                                <Badge color="pink" size="xs">{item.created_at}</Badge>
                              </TableCell>
                              <TableCell className="text-right">
                                <Button
                                  size="xs"
                                  variant="secondary"
                                  color="gray"
                                  onClick={() => navigate(`/hashtag/${item?.id}`)}
                                >
                                  View Report
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                    <div className="mt-5">
                      <Button
                        variant="secondary"
                        color="gray"
                        className="ml-auto block"
                        onClick={closeModal}
                      >
                        Close
                      </Button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      )}
    </>
  );
}
