/* eslint-disable react/prop-types */
import React, { Fragment, useState } from "react";
import {
  Box,
  CardHeader,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  IconButton,
  Avatar,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { convertNumberFormatter } from "../../utils/nFormatter";
import timeAgo from "../../utils/DateConverter";
import NoData1 from "../../images/NoData/NoCompare.avif";
import NoData2 from "../../images/NoData/NoChartsData.avif";


const columns = [
  { id: "user", label: "Handle Name", minWidth: 150 },
  { id: "post", label: "Post", align: "center", maxWidth: 150 },
  { id: "likes", label: "Likes", align: "center", minWidth: 100 },
  { id: "comments", label: "Comments", align: "center", minWidth: 100 },
  { id: "views", label: "Views", align: "center", minWidth: 100 },
  { id: "createdAt", label: "Posted", align: "center", minWidth: 150 },
  { id: "actions", label: "Actions", align: "center", minWidth: 100 },
];

const PostsContainer = ({ campaign, title }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [expandedPosts, setExpandedPosts] = useState({});

  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const toggleReadMore = (index) => {
    setExpandedPosts((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  return (
    <>
      <Fragment>
        <CardHeader title={title} />
        {campaign?.length > 0 ? (
          <Paper sx={{ width: "100%", overflow: "hidden", margin: ".5rem 0" }}>
            <TableContainer>
              <Table stickyHeader aria-label="posts table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {campaign
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((post, i) => (
                      <TableRow key={i} hover>
                        <TableCell style={{ display: "flex", alignItems: "center" }}>
                          <Avatar
                            alt={post.user}
                            src={post.profile_link}
                            sx={{ marginRight: 2 }}
                          >
                            {/* Safeguard against undefined handle_name */}
                            {post.handle_name?.charAt(0).toUpperCase() || "?"}
                          </Avatar>
                          {post.handle_name || "Unknown User"}
                        </TableCell>
                        <TableCell align="center">
                          {post.post && post.post.split(" ").length > 4 && !expandedPosts[i]
                            ? (
                              <>
                                {post.post.split(" ").slice(0, 4).join(" ")}...{" "}
                                <span
                                  style={{ color: "blue", cursor: "pointer" }}
                                  onClick={() => toggleReadMore(i)}
                                >
                                  read more
                                </span>
                              </>
                            )
                            : (
                              <>
                                {post.post || ""}{" "}
                                {post.post && post.post.split(" ").length > 4 && (
                                  <span
                                    style={{ color: "blue", cursor: "pointer" }}
                                    onClick={() => toggleReadMore(i)}
                                  >
                                    show less
                                  </span>
                                )}
                              </>
                            )}
                        </TableCell>
                        <TableCell align="center">
                          {convertNumberFormatter(post.likes)}
                        </TableCell>
                        <TableCell align="center">
                          {convertNumberFormatter(post.comments)}
                        </TableCell>
                        <TableCell align="center">
                          {convertNumberFormatter(post.views)}
                        </TableCell>
                        <TableCell align="center">
                          {timeAgo(post.created_at)}
                        </TableCell>
                        <TableCell align="center">
                          <Tooltip title="View Post">
                            <IconButton onClick={() => window.open(post.link, "_blank")}>
                              <VisibilityIcon color="info" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={campaign.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        ) : (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <img
              loading="lazy"
              src={title === "Organic Posts" ? NoData1 : NoData2}
              alt="NoData"
              style={{ width: "400px" }}
            />
            <Typography style={{ fontSize: "1.02rem", fontWeight: "600" }}>
              {title} data is not available
            </Typography>
          </Box>
        )}
      </Fragment>
    </>
  );
};

export default PostsContainer;
