import React from 'react'
import Box from "@mui/material/Box";
import { Breadcrumbs, CardHeader, Typography } from '@mui/material';
import UserNotificationSettings from './UserNotificationSettings';
import { Link } from 'react-router-dom';

const UserNotification = () => {
    return (
        <div>
            <div style={{ display: "flex" }}>
                <Breadcrumbs
                    style={{
                        margin: "1.5rem .85rem",
                        marginBottom: "1%",
                        marginLeft: "1.5rem",
                    }}
                    aria-label="breadcrumb"
                >
                    <Link
                        style={{ cursor: "pointer" }}
                        underline="hover"
                        color="inherit"
                        to={"/"}
                    >
                        Home
                    </Link>
                    <Typography color="text.primary">Notification</Typography>
                </Breadcrumbs>
            </div>
            <Box
                style={{
                    width: "100%",
                    borderTop: "0.3px solid rgba(212, 209, 209, 0.78)",
                }}
            />
            <Box
                sx={{
                    bgcolor: "background.paper",
                    mt: 1,
                    width: "100%",
                }}
            >
            </Box>
            <Box sx={{ ml: 3 }}>
                <CardHeader
                    subheader="Select how you'll be notified when the following changes occur."
                    title="General Notification"
                    sx={{ m: 0, p: 0, mt: 3 }}
                />
                <UserNotificationSettings />
            </Box>
        </div>
    )
}

export default UserNotification
