/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Backdrop,
  Breadcrumbs,
  Button,
  CardHeader,
  CircularProgress,
  Container,
  Grid,
  LinearProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import CircularStatic from "../../components/competitor/analysis/Circular_Progress";
import { useDispatch, useSelector } from "react-redux";
import nFormatter from "../../utils/nFormatter";
import { getCompetitorReport } from "../../context/slice/CompetitorSlice";
import HastagsWordCloud from "../../components/influencers/stats/HastagsWordCloud";
import { getCompetitors } from "../../context/slice/userSlice";
import CircularLoader from "../../components/shared/CircularLoader";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddCompetitorModal from "../../components/competitor/AddCompetitorModal";
import NoCompetitors from "../../images/NoData/NoCompetitors.avif";
import { Link, useParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { toastOptions } from "../../utils/toastOptions";
import InfluencersPosts from "../../components/campaigns/CampaignDetailPage/InfluencersPosts";
import PaidPatnership from "../../images/paidPatnership.avif";
import timeAgo from "../../utils/DateConverter";
import NoReachedPost from "../../images/404page/NoData.avif";
import AllPostsDrawer from "../../components/influencers/overview/AllPaidPromotions";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import FootpryntLogo from "../../images/FootPryntLowOpacityLogo.png";
import DefaultUser from "../../images/defaultUserImg.png";
import Twitter from "../../images/social_media/twitterLogo.png";
import Youtube from "../../images/social_media/youtubeLogo.png";
import Instagram from "../../images/social_media/instagramLogo.jfif";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import {
  Grid as TremorGrid,
  Card,
  Text,
  Metric,
  Icon,
  Flex,
  BadgeDelta,
  Title,
  Button as TremorButton,
} from "@tremor/react";
import InstagramIcon from "@mui/icons-material/Instagram";
import DownloadIcon from "@mui/icons-material/Download";
import MarkunreadIcon from "@mui/icons-material/Markunread";
import ChatIcon from "@mui/icons-material/Chat";
import FavoriteIcon from "@mui/icons-material/Favorite";
import VisibilityIcon from "@mui/icons-material/Visibility";
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import {
  CashIcon,
  TicketIcon,
  UserGroupIcon,
  ArrowNarrowRightIcon,
  HeartIcon,
} from "@heroicons/react/solid";
import AgeWiseBreakDown from "../../components/influencers/analytics/AgeWiseBreakDown";
import FrequencyPostsChart from "../../components/influencers/analytics/FrequencyPostsChart";
import LikesPerformanceChart from "../../components/influencers/analytics/LikesPerformanceChart";
import FollowersAreaChart from "../../components/influencers/analytics/FollowersAreaChart";
import TraffivByDevicePieChart from "../../components/influencers/analytics/TraffivByDevicePieChart";
import GenderFollowersPieChart from "../../components/influencers/analytics/GenderFollowersPieChart";
import EngagementRateChart from "../../components/influencers/analytics/EngagementRateChart";
// import { influencerDetailsMock as competitor } from "../../__mocks__/influencerReport";
import SendEmailModal from "../../components/influencers/SendEmailModal";
import { Link as RouterLink } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getUserSubscriptionHistory } from "../../context/slice/SubscriptionSlice";
import PDFLoader from "../../assets/generatingPDFloader.gif";
import OfflineBoltIcon from "@mui/icons-material/OfflineBolt";
import PageLoader from "../loader/PageLoader";

const CompetitorDetailPg = () => {
  const [openEmail, setOpenEmail] = useState(false);
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false);

  const dispatch = useDispatch();
  const [isAvaliable, setIsAvaliable] = useState(true);
  //
  // singleCompetitorDetail: competitor,
  const {
    loading: competitorDetailPageLoading,
    singleCompetitorDetail: competitor,
  } = useSelector((store) => store.competitor);

  const page1 = useRef(null);
  const page2 = useRef(null);

  const { competitors, loading: competitorsLoading } = useSelector(
    (store) => store.users
  );

  const { subscription_history, loading: subscriptionLoading } = useSelector(
    (store) => store.subscription
  );

  const isExpired =
    !subscriptionLoading && (subscription_history?.[0]?.credits_remaining || 0);

  useEffect(() => {
    dispatch(getCompetitorReport({ id }))
      .then(unwrapResult)
      .then(() => setIsAvaliable(true))
      .then(() => dispatch(getUserSubscriptionHistory()))
      .catch((error) => {
        setIsAvaliable(false);
        toast.error(error.message, toastOptions);
      });
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getCompetitors());
  }, []);

  console.log(
    competitor,
    !competitor || Object.keys(competitor || {}).length === 0
  );

  const pdfGenerate = async () => {
    setPdfLoading((prev) => !prev);
    const pg1 = page1.current;
    const pg2 = page2.current;
    // const pg3 = page3.current;
    // const pg4 = page4.current;
    let pages = [pg1, pg2];

    const canvas1 = await html2canvas(pages[0], {
      allowTaint: true,
      logging: true,
      letterRendering: 1,
      useCORS: true,
    });
    const canvas2 = await html2canvas(pages[1], {
      allowTaint: true,
      logging: true,
      letterRendering: 1,
      useCORS: true,
    });
    // const canvas3 = await html2canvas(pages[2], {
    //   allowTaint: true,
    //   logging: true,
    //   letterRendering: 1,
    //   useCORS: true,
    // });
    // const canvas4 = await html2canvas(pages[3], {
    //   allowTaint: true,
    //   logging: true,
    //   letterRendering: 1,
    //   useCORS: true,
    // });
    const imageWidth = 200;
    const imageHeight = (canvas1.height * imageWidth) / canvas1.width;
    const imgdata = canvas1.toDataURL("img/png");
    let doc = new jsPDF("p", "mm", "a4");
    doc.addImage(imgdata, "png", 0, 0, imageWidth, imageHeight);
    doc.setTextColor(150);
    doc.addImage(FootpryntLogo, "png", 10, 100, 180, 50);
    doc.addPage();
    const imageHeight2 = (canvas2.height * imageWidth) / canvas2.width;
    const imgdata2 = canvas2.toDataURL("img/png");
    doc.addImage(imgdata2, "png", 0, 0, imageWidth, imageHeight2);
    doc.setTextColor(150);
    doc.addImage(FootpryntLogo, "png", 10, 100, 180, 50);
    doc.addPage();
    setPdfLoading((prev) => !prev);
    await doc.save(`${competitor?.handle}.pdf`);
  };

  const widgets = [
    {
      title: "Total Followers",
      metric: nFormatter(competitor?.follower_count | 0) || 0,
      icon: UserGroupIcon,
      color: "indigo",
      rate: competitor?.followers_growth?.percent,
      status: competitor?.followers_growth?.status,
    },
    {
      title: "Total Posts",
      metric: competitor?.total_posts || 0,
      icon: CashIcon,
      color: "fuchsia",
      rate: competitor?.posts_growth?.percent,
      status: competitor?.posts_growth?.status,
    },
    {
      title: "Engagement Rate",
      metric: competitor?.avg_er_till_yesterday?.toFixed(2) || 0 + "%",
      icon: TicketIcon,
      color: "amber",
      rate: competitor?.engagement_rate_growth?.percent,
      status: competitor?.engagement_rate_growth?.status,
    },
  ];

  const posts = [
    {
      title: "Most Liked Post",
      metric: nFormatter(competitor?.most_liked_post?.likes | 0),
      icon: <FavoriteIcon color="error" sx={{ fontSize: "3rem" }} />,
      color: "rose",
      link: competitor?.most_liked_post?.post_link,
    },
    {
      title: "Last Post Date",
      metric: competitor?.most_update_post?.updated_at
        ? new Date(competitor?.most_update_post?.updated_at).toLocaleDateString()
        : "N/A",
      icon: <UpdateOutlinedIcon color="error" sx={{ fontSize: "3rem" }} />,
      color: "fuchsia",
      link: competitor?.most_update_post?.post_link,
    },

    {
      title: "Most Commented Post",
      metric: nFormatter(competitor?.most_commented_post?.comments | 0),
      icon: <ChatIcon sx={{ fontSize: "3rem", color: "#22c55e" }} />,
      color: "green",
      link: competitor?.most_commented_post?.post_link,
    },
    {
      title:
        competitor?.social_media === "twitter"
          ? "Most ReTwetted Post"
          : "Most Viewed Post",
      metric:
        competitor?.social_media === "twitter"
          ? nFormatter(competitor?.most_shared_post?.retweet_count | 0)
          : nFormatter(competitor?.most_viewed_post?.views | 0),
      icon:
        competitor?.social_media === "twitter" ? (
          <TwitterIcon color="info" sx={{ fontSize: "3rem" }} />
        ) : (
          <VisibilityIcon color="primary" sx={{ fontSize: "3rem" }} />
        ),
      color: "blue",
      link:
        competitor?.social_media === "twitter"
          ? competitor?.most_shared_post?.post_link
          : competitor?.most_viewed_post?.post_link,
    },
  ];

  if (competitorDetailPageLoading || competitorsLoading) {
    return <PageLoader />;
  }

  if (
    !competitorDetailPageLoading &&
    (!isAvaliable || !competitor || Object.keys(competitor || {}).length === 0)
  ) {
    return (
      <Box
        component="main"
        sx={{
          alignItems: "center",
          display: "flex",
          flexGrow: 1,
          minHeight: "100%",
        }}
        style={{ margin: "2rem 0", marginTop: "1rem" }}
      >
        <Container maxWidth="md">
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography align="center" color="textPrimary" variant="h3">
              {isExpired == 0
                ? "The Competitor Report that you are looking for is not Accessible"
                : "The Competitor profile/report you are looking for is currently generating..."}
            </Typography>
            <Typography
              sx={{ pb: 0, mb: 0 }}
              align="center"
              color="textPrimary"
              variant="subtitle1"
            >
              {isExpired == 0
                ? "Kindly recharge your plan to view more competitors"
                : "Please wait for a few minites. For now try using the navigation"}
            </Typography>
            <Box sx={{ textAlign: "center" }}>
              <img
                loading="lazy"
                alt="Under development"
                src={NoReachedPost}
                style={{
                  display: "inline-block",
                  maxWidth: "100%",
                  width: 400,
                }}
              />
            </Box>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <RouterLink to={"/competitor-analysis"}>
                <Button
                  startIcon={<ArrowBackIcon fontSize="small" />}
                  sx={{ mt: 0.2, mb: 0 }}
                  variant="contained"
                >
                  Go back to Competitor Page
                </Button>
              </RouterLink>
              {isExpired == 0 ? (
                <RouterLink to={"/user-profile?tab=3"}>
                  <Button
                    startIcon={<OfflineBoltIcon fontSize="small" />}
                    sx={{ mt: 0.2, mb: 0, ml: 2 }}
                    variant="contained"
                    color="error"
                  >
                    Recharge Now
                  </Button>
                </RouterLink>
              ) : null}
            </Box>
          </Box>
        </Container>
      </Box>
    );
  }

  if (
    (!competitorsLoading || !competitorDetailPageLoading) &&
    (competitors.length > 0 || parseInt(id) === 1) &&
    competitor &&
    Object.keys(competitor || {}).length > 0
  ) {
    return (
      <React.Fragment>
        {pdfLoading && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
          >
            <img
              src={PDFLoader}
              alt="PDFLoader"
              style={{ objectFit: "cover" }}
            />
          </Backdrop>
        )}
        <div style={{ display: "flex" }}>
          <Breadcrumbs
            style={{
              margin: "1.5rem .85rem",
              marginBottom: "0%",
              marginLeft: "1.5rem",
            }}
            aria-label="breadcrumb"
          >
            <Link
              style={{ cursor: "pointer" }}
              underline="hover"
              color="inherit"
              to={"/"}
            >
              Home
            </Link>
            <Link
              style={{ cursor: "pointer" }}
              underline="hover"
              color="inherit"
              to={"/competitor-analysis"}
            >
              Competitors
            </Link>
            <Typography color="text.primary">Competitor Detail</Typography>
          </Breadcrumbs>
        </div>
        <div ref={page1}>
          <section className="flex flex-col lg:flex-row justify-center items-center p-4 ">
            <div className="border-gray-200 border-2 p-4 rounded h-[300px] flex flex-col justify-between items-center">
              <h1 className="text-xl font-medium text-center cursor-pointer text-blue-900 hover:underline">
                @{competitor?.handle || "Anonymous"}
              </h1>
              <div className="relative">
                <img
                  loading="lazy"
                  className="w-[140px] h=[140px] rounded-full"
                  src={competitor?.profile_link || DefaultUser}
                  alt={competitor?.handle}
                  onError={(e) => {
                    e.currentTarget.src = DefaultUser;
                  }}
                />
                {competitor?.social_media === "twitter" && (
                  <img
                    loading="lazy"
                    className="w-[40px] absolute bottom-0 right-0 rounded-full"
                    src={Twitter}
                    alt={"twitter"}
                  />
                )}
                {competitor?.social_media === "youtube" && (
                  <img
                    loading="lazy"
                    className="w-[40px] absolute bottom-0 right-0 rounded-full"
                    src={Youtube}
                    alt={"YouTube"}
                  />
                )}
                {competitor?.social_media === "instagram" && (
                  <img
                    loading="lazy"
                    className="w-[40px] absolute bottom-0 right-0 rounded-full"
                    src={Instagram}
                    alt={"Instagram"}
                  />
                )}
              </div>
              <div className="flex flex-col justify-center items-center mt-1 w-full">
                <div className="flex flex-row justify-between items-center w-full">
                  <span className="font-medium text-sm">Category</span>
                  <span className="font-medium text-sm">
                    {competitor?.category || ""}
                  </span>
                </div>
                <div className="flex flex-row justify-between items-center w-full">
                  <span className="font-medium text-sm">Location</span>
                  <span className="font-medium text-sm">
                    {competitor?.location || "India"}
                  </span>
                </div>
              </div>
              <Button
                variant="contained"
                color="primary"
                sx={{ mt: 0.5 }}
                startIcon={
                  competitor?.social_media === "twitter" ? (
                    <TwitterIcon />
                  ) : competitor?.social_media === "instagram" ? (
                    <InstagramIcon />
                  ) : (
                    <YouTubeIcon />
                  )
                }
                size="small"
                href={competitor?.displayname}
                disabled={!competitor?.displayname}
                target="_blank"
                rel="noopener noreferrer"
              >
                {competitor?.social_media
                  ? "View Profile on " +
                  competitor?.social_media?.charAt(0).toUpperCase() +
                  competitor?.social_media?.slice(1)
                  : "Not Avaliable"}
              </Button>
            </div>
            <TremorGrid
              numItems={1}
              numItemsSm={2}
              numItemsLg={3}
              className="gap-2 flex-1 pl-4"
            >
              {widgets.map((item) => (
                <Card
                  key={item.title}
                  decoration="top"
                  decorationColor={item.color}
                >
                  <Flex justifyContent="between" className="space-x-4 w-full">
                    <div className="truncate">
                      <Text>{item.title}</Text>
                      <Metric className="truncate">{item.metric}</Metric>
                    </div>
                    <Icon
                      icon={item.icon}
                      variant="light"
                      size="xl"
                      color={item.color}
                    />
                  </Flex>
                  <Flex justifyContent="start" className="space-x-2 mt-4">
                    <BadgeDelta
                      deltaType={
                        item.status === "decreased"
                          ? "moderateDecrease"
                          : "moderateIncrease"
                      }
                    />
                    <Flex justifyContent="start" className="space-x-1 truncate">
                      <Text color="emerald">
                        {item?.rate?.toFixed(2) || 0} %
                      </Text>
                      <Text className="truncate">last 24 hours</Text>
                    </Flex>
                  </Flex>
                </Card>
              ))}
              <Flex justifyContent="between" className="space-x-2 w-full">
                <div
                  style={{
                    // marginRight: "5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    width: "250px",
                    border: "1px solid rgb(209 213 219 /1)",
                    borderRadius: "10px",
                    height: "150px",
                    cursor: "pointer",
                  }}
                >
                  <Title>Export</Title>
                  <DownloadIcon
                    sx={{ color: "purple", width: "60px", height: "60px" }}
                  />
                  <TremorButton
                    size="sm"
                    variant="light"
                    iconPosition="right"
                    className="mt-1"
                    onClick={() => pdfGenerate()}
                  >
                    PDF
                  </TremorButton>
                </div>
                <div
                  style={{
                    // marginRight: "5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    width: "250px",
                    border: "1px solid rgb(209 213 219 /1)",
                    borderRadius: "10px",
                    height: "150px",
                    cursor: "pointer",
                  }}
                >
                  <Title>Contact</Title>
                  <MarkunreadIcon
                    sx={{ color: "blue", width: "60px", height: "60px" }}
                  />
                  <TremorButton
                    size="sm"
                    variant="light"
                    iconPosition="right"
                    className="mt-1"
                    onClick={() => setOpenEmail(true)}
                  >
                    E-mail
                  </TremorButton>
                </div>
              </Flex>
              <Card decoration="top" decorationColor={"red"}>
                <Flex justifyContent="between" className="space-x-4 w-full">
                  <div className="truncate">
                    <Text>Avg. Likes</Text>
                    <Metric className="truncate">
                      {nFormatter(
                        competitor?.account_analytics?.avg_likes_received | 0
                      )}
                    </Metric>
                  </div>
                  <Icon
                    icon={HeartIcon}
                    variant="light"
                    size="xl"
                    color={"red"}
                  />
                </Flex>
                <Flex justifyContent="start" className="space-x-2 mt-4">
                  <BadgeDelta
                    deltaType={
                      competitor?.avg_likes_rate?.status === "decreased"
                        ? "moderateDecrease"
                        : "moderateIncrease"
                    }
                  />
                  <Flex justifyContent="start" className="space-x-1 truncate">
                    <Text color="emerald">
                      {competitor?.avg_likes_rate?.rate?.toFixed(2) || 0}
                    </Text>

                    <Text className="truncate">last 24 hrs</Text>
                  </Flex>
                </Flex>
              </Card>
              <Card decoration="top" decorationColor={"blue"}>
                <Flex justifyContent="between" className="space-x-4 w-full">
                  <div className="truncate">
                    <Text>
                      Avg.{" "}
                      {competitor?.social_media === "twitter"
                        ? "ReTweet"
                        : "Comments"}
                    </Text>
                    <Metric className="truncate">
                      {competitor?.social_media === "twitter"
                        ? nFormatter(
                          parseInt(
                            competitor?.account_analytics?.avg_shares | 0
                          )
                        )
                        : nFormatter(
                          parseInt(
                            competitor?.account_analytics?.avg_comments | 0
                          )
                        )}
                    </Metric>
                  </div>
                  {competitor?.social_media === "twitter" ? (
                    <TwitterIcon color="info" sx={{ fontSize: "3rem" }} />
                  ) : (
                    <ChatIcon color="info" sx={{ fontSize: "3rem" }} />
                  )}
                </Flex>
                <Flex justifyContent="start" className="space-x-2 mt-4">
                  <BadgeDelta
                    deltaType={
                      competitor?.avg_comments_rate?.status === "decreased"
                        ? "moderateDecrease"
                        : "moderateIncrease"
                    }
                  />
                  <Flex justifyContent="start" className="space-x-1 truncate">
                    <Text color="emerald">
                      {competitor?.avg_comments_rate?.rate?.toFixed(2) || 0}
                    </Text>

                    <Text className="truncate">last 24 hrs</Text>
                  </Flex>
                </Flex>
              </Card>
            </TremorGrid>
          </section>
          <SendEmailModal open={openEmail} setOpen={setOpenEmail} />
          <TremorGrid
            numItems={1}
            numItemsSm={2}
            numItemsLg={4}
            className="gap-2 flex-1 pl-4"
          >
            {posts.map((item) => (
              <Card
                key={item.title}
                decoration="top"
                decorationColor={item.color}
              >
                <Flex justifyContent="between" className="space-x-4 w-full">
                  <div className="truncate">
                    <Text>{item.title}</Text>
                    <Metric className="truncate">{item.metric}</Metric>
                  </div>

                  {item.icon}
                </Flex>
                <TremorButton
                  size="sm"
                  variant="light"
                  iconPosition="right"
                  className="mt-1"
                  icon={ArrowNarrowRightIcon}
                >
                  <a
                    href={item.link?.startsWith("https://") ? item.link : "https://" + item.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Click Here to View Post
                  </a>
                </TremorButton>
              </Card>
            ))}
          </TremorGrid>
          <TremorGrid numItemsMd={2} className="mt-6 gap-6 p-4">
            <EngagementRateChart data={competitor?.engagement_rate} />
            <GenderFollowersPieChart
              data={competitor?.gender_wise_breakdown?.["M/FRatio"]}
            />
            <TraffivByDevicePieChart data={competitor?.trafic_by_device} />
            <FollowersAreaChart data={competitor?.followers} />
          </TremorGrid>
        </div>
        {/* <competitorAnalytics competitor={competitor} /> */}
        <div ref={page2}>
          <TremorGrid numItemsMd={2} className="mt-6 gap-6 p-4">
            <HastagsWordCloud
              hashtags={competitor?.hashtags}
              handlename={competitor?.handle}
            />
            <LikesPerformanceChart data={competitor?.followers} />
            <FrequencyPostsChart data={competitor?.frequency_of_posts} />
            <AgeWiseBreakDown data={competitor?.gender_wise_breakdown} />
            {/* <RegionWiseBreakDown data={competitor?.gender_wise_breakdown} /> */}
          </TremorGrid>

          <Grid item lg={12} md={12} xl={9} xs={12}>
            <CardHeader
              title={"Latest Paid Promotions"}
              subheader={"Latest Posts of " + competitor?.handle}
              action={
                competitor?.paid_partnership?.length > 10 && (
                  <AllPostsDrawer influencer={competitor} />
                )
              }
            />
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                width: "100%",
                padding: "1rem",
                gridGap: ".85rem",
              }}
            >
              {(competitor?.paid_partnership?.length === 0 ||
                !competitor?.paid_partnership) && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      marginBottom: "2rem",
                    }}
                  >
                    <img
                      loading="lazy"
                      width={"400px"}
                      src={PaidPatnership}
                      alt="nopaidpatnership"
                    />
                    <p>No Paid Patnerships done by {competitor?.handle}</p>
                  </div>
                )}
              {competitor?.paid_partnership?.slice(0, 10)?.map((post, i) => {
                return (
                  <InfluencersPosts
                    image={competitor?.profile_link}
                    key={i + 1}
                    user={competitor?.fullname || competitor?.handle}
                    title={post.title}
                    likes={post.likes}
                    socialMedia={competitor?.social_media}
                    comments={post.comments}
                    views={post.views}
                    shares={post.shares || post.retweet_count}
                    desc={post.post || post.tweet}
                    createdAt={timeAgo(post?.created_at)}
                    hashtags={post?.post
                      ?.split(" ")
                      .filter((h) => h.includes("#"))}
                    link={post?.post_link}
                  />
                );
              })}
            </div>
          </Grid>
        </div>
      </React.Fragment>
    );
  }

  if (
    !competitorDetailPageLoading &&
    !competitorsLoading &&
    competitors.length === 0 &&
    competitor &&
    Object.keys(competitor || {}).length > 0
  ) {
    return (
      <React.Fragment>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "70vh",
            width: "100%",
          }}
        >
          <img
            loading="lazy"
            src={NoCompetitors}
            alt="NoCompetitors"
            style={{ width: "400px", borderRadius: "15px" }}
          />
          <Typography
            style={{
              fontSize: "1.35rem",
              fontWeight: "600",
              marginTop: "1rem",
              letterSpacing: "1px",
            }}
          >
            No Competitors Avaliable
          </Typography>
          <Button
            startIcon={<AddCircleOutlineIcon fontSize="small" />}
            variant="contained"
            onClick={() => setOpen(true)}
            style={{ marginTop: "1rem" }}
          >
            Add Competitor
          </Button>
          <AddCompetitorModal open={open} setOpen={setOpen} />
        </div>
      </React.Fragment>
    );
  }
};

export default CompetitorDetailPg;
