import React from 'react'
import Box from "@mui/material/Box";
import { Breadcrumbs, CardHeader, Typography, useMediaQuery } from '@mui/material';
import FavInfluencersContainer from '../../components/influencers/favInfluencers/FavInfluencersContainer';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from "@reduxjs/toolkit";
import {
    getAllFavInfluencers,
    removeInfluencerFromFavourite,
} from "../../context/slice/FavouriteInfluencerSlice"
import { apihandleActions } from "../../context/slice/ApiCalls"
import { Link } from 'react-router-dom';

const UserInfluencers = () => {

    const { favInfluencers } = useSelector((store) => store.favouriteInfluencer);
    const dispatch = useDispatch();
    const { user } = useSelector((store) => store.users);
    const mediaQuery2 = useMediaQuery("(min-width:560px)");
    const removeFavInfHandler = (inf) => {
        const favItem = favInfluencers?.find(
            (favinf) => favinf?.influencer_handle_name === inf?.influencer_handle_name
        );
        dispatch(removeInfluencerFromFavourite(favItem.fav_influencer_id))
            .then(unwrapResult)
            .then(() => {
                dispatch(getAllFavInfluencers({ id: user?.user?.id }));
            });
        dispatch(apihandleActions.startFavInfluencersCall());
    };


    return (
        <div>
            <div style={{ display: "flex" }}>
                <Breadcrumbs
                    style={{
                        margin: "1.5rem .85rem",
                        marginBottom: "1%",
                        marginLeft: "1.5rem",
                    }}
                    aria-label="breadcrumb"
                >
                    <Link
                        style={{ cursor: "pointer" }}
                        underline="hover"
                        color="inherit"
                        to={"/"}
                    >
                        Home
                    </Link>
                    <Typography color="text.primary">Favourite Influencers</Typography>
                </Breadcrumbs>
            </div>
            <Box
                style={{
                    width: "100%",
                    borderTop: "0.3px solid rgba(212, 209, 209, 0.78)",
                }}
            />
            <Box
                sx={{
                    bgcolor: "background.paper",
                    mt: 1,
                    width: "100%",
                }}
            >
            </Box>
            <div>
                <CardHeader
                    style={{ width: "98%" }}
                    subheader="See analytics of your favourite influencers"
                    title="Favourite Influencers"
                    sx={{ mb: 0, pb: 0, pt: 2.5 }}
                />
                <FavInfluencersContainer
                    removeFavInfHandler={removeFavInfHandler}
                    favInfluencers={favInfluencers}
                    mediaQuery={mediaQuery2}
                    showDetail={true}
                />

            </div>
        </div>
    )
}

export default UserInfluencers
