/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BaseURL } from "../../services/API_services";
import { signOut } from "firebase/auth";
import { firebaseAuth } from "../../firebase/firebase.config";
import { toast } from "react-toastify";
import { toastOptions } from "../../utils/toastOptions";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

const LoggedInUser = localStorage.getItem("footprynt_pride_key")
  ? JSON.parse(localStorage.getItem("footprynt_pride_key"))
  : null;

export const userSocialMediaLogin = createAsyncThunk(
  "users/socialMediaLogin",
  async (token, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await axios.post(`${BaseURL}common/social_media`, {}, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
      });
      if (res?.response?.status >= 400) {
        return rejectWithValue(res.response.data);
      }
      const userResponse = await axios.get(`${BaseURL}common/userprofile/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (userResponse?.response?.status >= 400) {
        return rejectWithValue(userResponse.response.data);
      }
      const profileComplete = await axios.get(
        `${BaseURL}common/profile_completion_percentage/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (profileComplete?.response?.status >= 400) {
        return rejectWithValue(profileComplete.response.data);
      }
      const data = {
        token: { access: token },
        user: userResponse?.data,
        profileComplete: profileComplete?.data?.profile_percent,
      };
      //console.log(data);
      localStorage.setItem("footprynt_pride_key", JSON.stringify(data));
      return fulfillWithValue(data);
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllUsers = createAsyncThunk(
  "users/getAll",
  async (user, { rejectWithValue, getState }) => {
    try {
      const res = await axios.get(`${BaseURL}common/users/`);
      return res.data;
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSingleUsers = createAsyncThunk(
  "users/getSingle",
  async (id, { rejectWithValue, getState }) => {
    try {
      const res = await axios.get(`${BaseURL}common/users/${id}`);
      return res.data;
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const addUser = createAsyncThunk(
  "users/add",
  async (user, { rejectWithValue, getState }) => {
    try {
      const res = await axios.post(`${BaseURL}common/users/`, user);
      return res.data;
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const editUser = createAsyncThunk(
  "users/edit",
  async (user, { rejectWithValue, fulfillWithValue }) => {
    // console.log("user----", user)
    try {
      await axios.put(`${BaseURL}common/userprofile/`, user);
      const profileComplete = await axios.get(
        `${BaseURL}common/profile_completion_percentage/`
      );
      const userResponse = await axios.get(`${BaseURL}common/userprofile/`);
      const data = JSON.parse(localStorage.getItem("footprynt_pride_key"));
      const userLocalStorageData = {
        token: data.token,
        user: userResponse.data,
        profileComplete: profileComplete.data.profile_percent,
      };
      localStorage.setItem("footprynt_pride_key", JSON.stringify(userLocalStorageData));
      return fulfillWithValue(userLocalStorageData);
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "users/delete",
  async (id, { rejectWithValue, getState }) => {
    try {
      const res = await axios.delete(`${BaseURL}common/users/${id}`);
      return res.data;
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const userLogout = createAsyncThunk(
  "users/logout",
  async (_payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      await signOut(firebaseAuth)
      localStorage.removeItem("footprynt_pride_key");
      return fulfillWithValue({ msg: "LogOut Successfully" });
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getProfileComplete = createAsyncThunk(
  "user/getProfileComplete",
  async (id, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await axios.get(
        `${BaseURL}common/profile_completion_percentage/`
      );
      if (res?.response?.status >= 400) {
        return rejectWithValue(res.response.data);
      }
      return fulfillWithValue(res.data);
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const insertCompetitor = createAsyncThunk(
  "user/insertCompetitor",
  async (user, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await axios.post(`${BaseURL}common/competitors`, user);
      if (res?.response?.status >= 400) {
        return rejectWithValue(res.response.data);
      }
      return fulfillWithValue(res.data);
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCompetitors = createAsyncThunk(
  "user/getCompetitors",
  async (id, { rejectWithValue, dispatch, getState }) => {
    try {
      const res = await axios.get(`${BaseURL}common/competitors`);
      if (res?.response?.status >= 400) {
        return rejectWithValue(res.response.data);
      }
      return res.data;
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCompetitorDetail = createAsyncThunk(
  "user/getCompetitorDetail",
  async (id, { rejectWithValue, dispatch, getState }) => {
    try {
      const res = await axios.get(`${BaseURL}common/competitors/${id}`);
      if (res?.response?.status >= 400) {
        return rejectWithValue(res.response.data);
      }
      return res.data;
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const editCompetitor = createAsyncThunk(
  "user/editCompetitor",
  async (payload, { rejectWithValue }) => {
    try {
      const res = await axios.put(
        `${BaseURL}common/competitors?id=${payload?.id}`,
        payload
      );
      if (res?.response?.status >= 400) {
        return rejectWithValue(res.response.data);
      }
      return res.data;
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteCompetitor = createAsyncThunk(
  "user/deleteCompetitors",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axios.delete(`${BaseURL}common/competitors?id=${id}`);
      if (res?.response?.status >= 400) {
        return rejectWithValue(res.response.data);
      }
      return res.data;
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const UserAnalytics = createAsyncThunk(
  "user/UserAnalytics",
  async (_payload, { rejectWithValue }) => {
    try {
      const res = await axios.get(`${BaseURL}common/user_report/`);
      if (res?.response?.status >= 400) {
        return rejectWithValue(res.response.data);
      }
      return res.data;
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const enableNotification = createAsyncThunk(
  "user/enableNotification",
  async (payload, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${BaseURL}common/notifications/`, payload);
      if (res?.response?.status >= 400) {
        return rejectWithValue(res.response.data);
      }
      return res.data;
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      ////console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);


export const getNotificationDetails = createAsyncThunk(
  "user/getNotificationDetails",
  async (_payload, { rejectWithValue }) => {
    try {
      const response = await axios.get('${BaseURL}common/notifications/`, config');
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const NotificationUpdate = createAsyncThunk(
  "user/NotificationUpdate",
  async (payload, { rejectWithValue }) => {
    console.log("conf-g-", payload.id);
    try {
      const response = await axios.put(`${BaseURL}common/notifications/?notification_id=${payload.id}`, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialError = {
  open: false,
  message: "",
  type: "success",
};

const userSlice = createSlice({
  name: "users",
  initialState: {
    loading: false,
    error: initialError,
    user: LoggedInUser,
    getUser: {},
    competitor: {},
    users: [],
    competitors: [],
    Competitordeleted: false,
    Competitoredited: false,
    registered: false,
    isLoggedIn: false,
    fetchedCompetitors: false,
    linkVerified: false,
    ytVerified: true,
    twitterVerified: true,
    instaVerified: true,
    notification: {},
    enabledNotification: false,
    userAnalytics: {},
    emailVerified: false,
  },
  reducers: {
    removeAlert(state, action) {
      state.error = initialError;
    },
    linkVerifiedAction(state, action) {
      state.linkVerified = action.payload;
    },
    setUserData(state, action) {
      const data = JSON.parse(localStorage.getItem("footprynt_pride_key"));
      const userLocalStorageData = {
        token: action.token,
        user: data.user,
        profileComplete: data.profileComplete,
      };
      localStorage.setItem("footprynt_pride_key", JSON.stringify(userLocalStorageData));
      state.user = userLocalStorageData;
    }
  },
  extraReducers: {
    [userSocialMediaLogin.pending]: (state, action) => {
      state.loading = true;
      state.error = initialError;
    },
    [userSocialMediaLogin.fulfilled]: (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.error.open = true;
      state.error.message = "User LoggedIn Successfully";
      state.isLoggedIn = true;
    },
    [userSocialMediaLogin.rejected]: (state, action) => {
      state.loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [userLogout.pending]: (state, action) => {
      state.loading = true;
      state.error = initialError;
    },
    [userLogout.fulfilled]: (state, action) => {
      state.loading = false;
      state.user = null;
      state.error.open = true;
      // state.error.message = action?.payload?.message;
      state.error.message = "User Logged Out";
      state.isLoggedIn = false;
      window.location.reload()
    },
    [userLogout.rejected]: (state, action) => {
      state.loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [getAllUsers.pending]: (state, action) => {
      state.loading = true;
    },
    [getAllUsers.fulfilled]: (state, action) => {
      state.loading = false;
      state.users = action.payload;
    },
    [getAllUsers.rejected]: (state, action) => {
      state.loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [addUser.pending]: (state, action) => {
      state.loading = true;
      state.error = initialError;
    },
    [addUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.user = action.payload.user;
    },
    [addUser.rejected]: (state, action) => {
      state.loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [getSingleUsers.pending]: (state, action) => {
      state.loading = true;
      state.error = initialError;
    },
    [getSingleUsers.fulfilled]: (state, action) => {
      state.loading = false;
      state.getUser = action.payload;
    },
    [getSingleUsers.rejected]: (state, action) => {
      state.loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [editUser.pending]: (state, action) => {
      state.loading = true;
      state.error = initialError;
    },
    [editUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.error.open = true;
      state.error.message = "User Updated Successfully";
      state.updatedData = action.payload;
      state.user = action.payload;
    },
    [editUser.rejected]: (state, action) => {
      state.loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [deleteUser.pending]: (state, action) => {
      state.loading = true;
      state.error = initialError;
    },
    [deleteUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.error.open = true;
      state.error.message = "User Deleted Successfully";
    },
    [deleteUser.rejected]: (state, action) => {
      state.loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [getProfileComplete.pending]: (state, action) => {
      state.loading = true;
      state.error = initialError;
    },
    [getProfileComplete.fulfilled]: (state, action) => {
      state.loading = false;
      state.profileCompleted = action.payload.profile_percent;
    },
    [getProfileComplete.rejected]: (state, action) => {
      state.loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [insertCompetitor.pending]: (state, action) => {
      state.loading = true;
      state.error = initialError;
      state.fetchedCompetitors = false;
    },
    [insertCompetitor.fulfilled]: (state, action) => {
      state.loading = false;
      state.error.open = true;
      state.error.message = "Competitors Added Successfully";
      state.fetchedCompetitors = true;
    },
    [insertCompetitor.rejected]: (state, action) => {
      state.loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [getCompetitors.pending]: (state, action) => {
      state.loading = true;
    },
    [getCompetitors.fulfilled]: (state, action) => {
      state.loading = false;
      state.competitors = action.payload;
    },
    [getCompetitors.rejected]: (state, action) => {
      state.loading = false;
      state.fetchedCompetitors = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [getCompetitorDetail.pending]: (state, action) => {
      state.loading = true;
    },
    [getCompetitorDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.competitor = action.payload;
    },
    [getCompetitorDetail.rejected]: (state, action) => {
      state.loading = false;
      state.fetchedCompetitors = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [deleteCompetitor.pending]: (state, action) => {
      state.loading = true;
      state.Competitordeleted = false;
    },
    [deleteCompetitor.fulfilled]: (state, action) => {
      state.loading = false;
      state.error.open = true;
      state.error.type = "success";
      state.error.message = "Competitor Deleted Successfully";
      state.Competitordeleted = true;
    },
    [deleteCompetitor.rejected]: (state, action) => {
      state.loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [editCompetitor.pending]: (state, action) => {
      state.loading = true;
      state.Competitoredited = false;
    },
    [editCompetitor.fulfilled]: (state, action) => {
      state.loading = false;
      state.error.open = true;
      state.error.message = "Competitor Updated Successfully";
      state.error.type = "success";
      state.Competitoredited = true;
    },
    [editCompetitor.rejected]: (state, action) => {
      state.loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [NotificationUpdate.pending]: (state, action) => {
      state.loading = true;
    },
    [NotificationUpdate.fulfilled]: (state, action) => {
      state.loading = false;
      state.error.open = true;
      state.error.message = "Updated Successfully";
      state.error.type = "success";
    },
    [NotificationUpdate.rejected]: (state, action) => {
      state.loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [getNotificationDetails.pending]: (state, action) => {
      state.loading = true;
    },
    [getNotificationDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.enabledNotification = true;
      state.notification = action.payload;
    },
    [getNotificationDetails.rejected]: (state, action) => {
      state.loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [enableNotification.pending]: (state, action) => {
      state.loading = true;
      state.enabledNotification = false;
    },
    [enableNotification.fulfilled]: (state, action) => {
      state.loading = false;
      state.enabledNotification = true;
      state.error.open = true;
      state.error.message = "You will be notified!!";
      state.error.type = "success";
    },
    [enableNotification.rejected]: (state, action) => {
      state.loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [UserAnalytics.pending]: (state, action) => {
      state.loading = true;
    },
    [UserAnalytics.fulfilled]: (state, action) => {
      state.loading = false;
      state.userAnalytics = action.payload;
    },
    [UserAnalytics.rejected]: (state, action) => {
      state.loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
  },
});

export const userActionns = userSlice.actions;

export default userSlice.reducer;