
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Box, Button, Divider, Typography, useTheme } from "@mui/material";
import NotificationImg from "../../images/NotificationPhoto.avif";
import {
  NotificationUpdate,
  enableNotification,
  getNotificationDetails,
} from "../../context/slice/userSlice";
import { apihandleActions } from "../../context/slice/ApiCalls";
import CircularLoader from "../../components/shared/CircularLoader";
import MultipleSelect from "../../components/settings/MultipleSelect";

// eslint-disable-next-line react/prop-types
const UserNotificationSettings = ({ setTab }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { user, notification, enabledNotification, loading } = useSelector(
    (s) => s.users
  );
  const { callNotifications } = useSelector((s) => s.apihandle);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      footprynt_marketing:
        notification?.[0]?.footprynt_marketing === true ? true : false,
      monthly_news_letter:
        notification?.[0]?.monthly_news_letter === true ? true : false,
      latest_trends_news_letters:
        notification?.[0]?.latest_trends_news_letters === true ? true : false,
    },
    validationSchema: Yup.object({
      footprynt_marketing: Yup.boolean().oneOf([true, false]),
      monthly_news_letter: Yup.boolean().oneOf([true, false]),
      latest_trends_news_letters: Yup.boolean().oneOf([true, false]),
    }),
    onSubmit: async (values) => {
      const data = {
        id: notification?.[0]?.id,
        footprynt_marketing: values.footprynt_marketing,
        monthly_news_letter: values.monthly_news_letter,
        latest_trends_news_letters: values.latest_trends_news_letters,
      };
      await dispatch(NotificationUpdate(data));
      dispatch(apihandleActions.startNotificationCall());
      setTab((prev) => prev + 1);
    },
  });

  const {
    handleSubmit,
    // handleBlur,
    handleChange,
    // errors,
    values,
    // touched,
  } = formik;

  useEffect(() => {
    if (callNotifications) {
      dispatch(getNotificationDetails());
      dispatch(apihandleActions.stopNotificationCall());
    }
  }, [enabledNotification, callNotifications, dispatch]);

  const enableNotificationHandler = () => {
    dispatch(
      enableNotification({
        user: user?.user?.id,
        footprynt_marketing: true,
        monthly_news_letter: true,
        latest_trends_news_letters: true,
      })
    );
    dispatch(apihandleActions.startNotificationCall());
  };

  // const isDisabled =
  //   notification?.[0]?.footprynt_marketing === values.footprynt_marketing &&
  //   notification?.[0]?.monthly_news_letter === values.monthly_news_letter &&
  //   notification?.[0]?.latest_trends_news_letters ===
  //   values.latest_trends_news_letters;

  // const handleNextWithDelay = () => {
  //   dispatch(apihandleActions.startNotificationCall());
  //   setTimeout(() => {
  //     setTab((prev) => prev + 1);
  //     dispatch(apihandleActions.stopNotificationCall());
  //   }, 1000);
  // };

  const flex = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  };

  return (
    <React.Fragment>
      {loading && <CircularLoader />}
      {!loading && notification?.length > 0 ? (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <img
            loading="lazy"
            style={{ width: "400px" }}
            src={NotificationImg}
            alt="NotificationImg"
          />
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography>
              Click Here to notify the latest updates of Footprynt Pride{" "}
            </Typography>
            <Button
              onClick={enableNotificationHandler}
              sx={{ mt: 2 }}
              variant="contained"
            >
              Enable Notification
            </Button>
          </Box>
        </Box>
      ) : (
        !loading && (

          <form
            style={{ ...flex, flexDirection: "column", alignItems: "flex-start" }}
            onSubmit={handleSubmit}
          >
            <div
              style={{
                ...flex,
                flexDirection: "row",
                justifyContent: "space-between",
                padding: "1rem 0",
              }}
            >
              <label>Footprynt Marketing</label>
              <MultipleSelect
                value={values.footprynt_marketing}
                onChange={handleChange}
                name={"footprynt_marketing"}
                id={"footprynt_marketing"}
              />
            </div>
            <Divider style={{ color: theme.palette.divider, width: "100%" }} />
            <div
              style={{
                ...flex,
                flexDirection: "row",
                justifyContent: "space-between",
                padding: "1rem 0",
              }}
            >
              <label>Monthly News letter</label>
              <MultipleSelect
                value={values.monthly_news_letter}
                onChange={handleChange}
                name={"monthly_news_letter"}
                id={"monthly_news_letter"}
              />
            </div>
            <Divider style={{ color: theme.palette.divider, width: "100%" }} />
            <div
              style={{
                ...flex,
                flexDirection: "row",
                justifyContent: "space-between",
                padding: "1rem 0",
              }}
            >
              <label>Latest trends news letter</label>
              <MultipleSelect
                value={values.latest_trends_news_letters}
                onChange={handleChange}
                name={"latest_trends_news_letters"}
                id={"latest_trends_news_letters"}
              />
            </div>
          </form>
        )
      )}
    </React.Fragment>
  );
};

export default UserNotificationSettings;
