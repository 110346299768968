/* eslint-disable react/prop-types */
import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ProfileForm from "../../components/settings/ProfileForm";
import SocialMediaHandlename from "../../components/settings/SocialMediaHandlename";
import * as Yup from "yup";
import { useFormik } from "formik";
import { apihandleActions } from "../../context/slice/ApiCalls";
import { editUser } from "../../context/slice/userSlice";
import CompanyDetails from "../../components/settings/CompanyDetails";
import { toast } from "react-toastify";
import LocationDetails from "../../components/settings/LocationDetails";

const profileSchema = Yup.object({
  fullName: Yup.string().required("Full Name is required"),
  email: Yup.string().email().required("Email is required"),
  phoneNo: Yup.string().matches(/^\d{10}$/, "Phone No. is invalid").required("Phone No. is required"),
  youtube_handle_name: Yup.string(),
  twitter_handle_name: Yup.string(),
  instagram_handle_name: Yup.string(),
  companyName: Yup.string(),
  companyLink: Yup.string(),
  companyEmail: Yup.string().email(),
  address_1: Yup.string(),
  city: Yup.string(),
  state: Yup.string().required("State is required"),
  country: Yup.string(),
  zipcode: Yup.string().matches(/^\d{6}$/, "Zipcode is invalid"),
});

const UserProfileSettings = () => {
  const { user } = useSelector((store) => store.users);
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Use navigate hook for redirection
  const [valid, setValid] = useState(true);

  const initialProfileValues = {
    fullName: user.user.fullName || "",
    email: user.user.email || "",
    phoneNo: user.user.phno || "",
    instagram_handle_name: user.user.instagram_handle_name || "",
    twitter_handle_name: user.user.twitter_handle_name || "",
    youtube_handle_name: user.user.youtube_handle_name || "",
    companyName: user.user.company || "",
    companyLink: user.user.company_link || "",
    companyEmail: user.user.company_email || "",
    address_1: user.user.address_1 || "",
    city: user.user.city || "",
    state: user.user.state || "",
    country: user.user.country || "",
    zipcode: user.user.zipcode ? String(user.user.zipcode) : "",
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: initialProfileValues,
    validationSchema: profileSchema,
    onSubmit: async (values) => {
      const userData = {
        id: user.user.id,
        fullName: values.fullName.trim() || null,
        email: values.email.trim() || null,
        phno: values.phoneNo || null,
        dp: user?.user?.dp,
        company: values.companyName.trim() || null,
        company_link: values.companyLink.trim() || null,
        company_email: values.companyEmail.trim() || null,
        instagram_handle_name: values.instagram_handle_name.trim() || null,
        twitter_handle_name: values.twitter_handle_name.trim() || null,
        youtube_handle_name: values.youtube_handle_name.trim() || null,
        address_1: values.address_1.trim() || null,
        city: values.city.trim() || null,
        state: values.state.trim() || null,
        country: values.country.trim() || null,
        zipcode: values.zipcode.trim() ? +values.zipcode : null,
      };

      if (valid) {
        dispatch(editUser({ ...userData }));
        dispatch(apihandleActions.startProfilecompleteCall());

        // Display success toast notification
        toast.success("Profile saved successfully! Please visit the dashboard.", {
          position: "top-right",
          autoClose: 3000, // Adjust autoClose duration as needed
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        navigate("/");
      } else {
        toast.error("Invalid URL");
      }
    },
  });

  const isDisable = (user?.user?.fullName === values.fullName || values.fullName.length === 0) &&
    (user?.user?.email === values.email || values.email.length === 0) &&
    (user?.user?.phno === values.phoneNo || values.phoneNo.length === 0) &&
    (user?.user?.instagram_handle_name === values.instagram_handle_name || values.instagram_handle_name.length === 0) &&
    (user?.user?.twitter_handle_name === values.twitter_handle_name || values.twitter_handle_name.length === 0) &&
    (user?.user?.youtube_handle_name === values.youtube_handle_name || values.youtube_handle_name.length === 0) &&
    (user?.user?.company_email === values.companyEmail || values.companyEmail.length === 0) &&
    (user?.user?.company === values.companyName || values.companyName.length === 0) &&
    (user?.user?.company_link === values.companyLink || values.companyLink.length === 0) &&
    (user?.user?.address_1 === values.address_1 || values.address_1.length === 0) &&
    (user?.user?.city === values.city || values.city.length === 0) &&
    (user?.user?.state === values.state || values.state.length === 0) &&
    (user?.user?.country === values.country || values.country.length === 0) &&
    (user?.user?.zipcode == values.zipcode || values.zipcode.length === 0);

  return (
    <>
      <ProfileForm
        values={values}
        errors={errors}
        touched={touched}
        handleChange={handleChange}
        handleBlur={handleBlur}
      />
      <SocialMediaHandlename
        values={values}
        errors={errors}
        touched={touched}
        handleChange={handleChange}
        handleBlur={handleBlur}
      />
      <CompanyDetails
        values={values}
        errors={errors}
        touched={touched}
        handleChange={handleChange}
        handleBlur={handleBlur}
        valid={valid}
        setValid={setValid}
      />
      <LocationDetails
        values={values}
        errors={errors}
        touched={touched}
        handleChange={handleChange}
        handleBlur={handleBlur}
        valid={valid}
        setValid={setValid}
      />
      <Box
        style={{
          marginTop: ".5rem",
          width: "98%",
          marginBottom: "0",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          flexDirection: "row",
        }}
      >
        <Button
          style={{ marginLeft: "1rem" }}
          onClick={handleSubmit}
          disabled={isDisable}
          type="submit"
          variant="contained"
        >
          Save
        </Button>
      </Box>
    </>
  );
};

export default UserProfileSettings;
